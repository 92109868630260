<div class="enzcontainerwhite">
  <div class="insideContainer">
    <div class="sectionTitle">
      <div class="titleTXT">{{ "forth.title" | translate }}</div>
      <div class="titleIMG"></div>
    </div>

    <div class="twocols m-3" *ngIf="first">
      <div class="question">
        <div class="questionTXT">
          <h2>{{ "forth.subtitle1" | translate }}</h2>
          <h5>{{ "forth.subtitleBl1" | translate }}</h5>
          <div class="text">
            {{ questions[0] }}
          </div>
        </div>
        <div class="sectionProgress">
          <div class="square active"></div>
          <div class="square"></div>
          <div class="square"></div>
          <div class="square"></div>
          <div class="square"></div>
        </div>
      </div>
      <div class="answers">
        <form [formGroup]="firstForm" novalidate>
          <div class="inputlabel" *ngFor="let data of firstData; let i = index">
            <input
              name="firstRadios"
              type="radio"
              [value]="data.value"
              (change)="onRadioChange($event, firstForm, i)"
            />
            <label>
              {{ data.name }}
            </label>
          </div>

          <!-- <p
            class="error"
            *ngIf="this.firstForm.controls['checkArray'].errors?.required"
          >
            Checkbox is required, select atleast one value.
          </p> -->

          <div class="btnSubmit">
            <input
              type="submit"
              value="{{ nextTxt }}"
              (click)="
                progress = 40;
                first = false;
                second = true;
                onSubmit(firstForm.value, 1)
              "
            />
          </div>
        </form>
      </div>
    </div>
    <div class="twocols m-3" *ngIf="second">
      <div class="question">
        <div class="questionTXT">
          <h2>{{ "forth.subtitle2" | translate }}</h2>
          <h5>{{ "forth.subtitleBl2" | translate }}</h5>
          <div class="text">
            {{ questions[1] }}
          </div>
        </div>
        <div class="sectionProgress">
          <div class="square"></div>
          <div class="square active"></div>
          <div class="square"></div>
          <div class="square"></div>
          <div class="square"></div>
        </div>
      </div>
      <div class="answers">
        <form [formGroup]="secondForm" novalidate>
          <div
            class="inputlabel"
            *ngFor="let data of secondData; let i = index"
          >
            <input
              name="secondRadios"
              type="radio"
              [value]="data.value"
              (change)="onRadioChange($event, secondForm, i)"
            />
            <label>
              {{ data.name }}
            </label>
          </div>

          <!-- <p
            class="error"
            *ngIf="this.secondForm.controls['checkArray'].errors?.required"
          >
            Checkbox is required, select atleast one value.
          </p> -->

          <div class="btnSubmit">
            <input
              type="submit"
              value="{{ nextTxt }}"
              (click)="
                progress = 60;
                third = true;
                second = false;
                onSubmit(secondForm.value, 2)
              "
            />
          </div>
        </form>
      </div>
    </div>
    <div class="twocols m-3" *ngIf="third">
      <div class="question">
        <div class="questionTXT">
          <h2>{{ "forth.subtitle3" | translate }}</h2>
          <h5>{{ "forth.subtitleBl3" | translate }}</h5>

          <div class="text">
            {{ questions[2] }}
          </div>
        </div>
        <div class="sectionProgress">
          <div class="square"></div>
          <div class="square"></div>
          <div class="square active"></div>
          <div class="square"></div>
          <div class="square"></div>
        </div>
      </div>
      <div class="answers">
        <form [formGroup]="thirdForm" novalidate>
          <div class="inputlabel" *ngFor="let data of thirdData; let i = index">
            <input
              name="thirdRadios"
              type="radio"
              [value]="data.value"
              (change)="onRadioChange($event, thirdForm, i)"
            />
            <label>
              {{ data.name }}
            </label>
          </div>

          <div class="btnSubmit">
            <input
              type="submit"
              value="{{ nextTxt }}"
              (click)="
                progress = 80;
                forth = true;
                third = false;
                onSubmit(thirdForm.value, 3)
              "
            />
          </div>
        </form>
      </div>
    </div>

    <div class="twocols m-3" *ngIf="forth">
      <div class="question">
        <div class="questionTXT">
          <h2>{{ "forth.subtitle4" | translate }}</h2>
          <h5>{{ "forth.subtitleBl4" | translate }}</h5>

          <div class="text">
            {{ questions[3] }}
          </div>
        </div>
        <div class="sectionProgress">
          <div class="square"></div>
          <div class="square"></div>
          <div class="square"></div>
          <div class="square active"></div>
          <div class="square"></div>
        </div>
      </div>
      <div class="answers">
        <form [formGroup]="forthForm" novalidate>
          <div class="inputlabel" *ngFor="let data of forthData; let i = index">
            <input
              name="forthRadios"
              type="radio"
              [value]="data.value"
              (change)="onRadioChange($event, forthForm, i)"
            />
            <label>
              {{ data.name }}
            </label>
          </div>

          <!-- <p
            class="error"
            *ngIf="this.forthForm.controls['checkArray'].errors?.required"
          >
            Checkbox is required, select atleast one value.
          </p> -->

          <div class="btnSubmit">
            <input
              type="submit"
              value="{{ nextTxt }}"
              (click)="
                progress = 100;
                fifth = true;
                forth = false;
                onSubmit(forthForm.value, 4)
              "
            />
          </div>
        </form>
      </div>
    </div>

    <div class="twocols m-3" *ngIf="fifth">
      <div class="empty" *ngIf="showNext"></div>
      <div class="question" *ngIf="!showNext">
        <div class="questionTXT">
          <h2>{{ "forth.subtitle5" | translate }}</h2>
          <h5>{{ "forth.subtitleBl5" | translate }}</h5>

          <div class="text">
            {{ questions[4] }}
          </div>
        </div>
        <div class="sectionProgress">
          <div class="square"></div>
          <div class="square"></div>
          <div class="square"></div>
          <div class="square"></div>
          <div class="square active"></div>
        </div>
      </div>
      <div class="answers">
        <form [formGroup]="fifthForm" novalidate>
          <div class="if" *ngIf="!showNext">
            <div
              class="inputlabel"
              *ngFor="let data of fifthData; let i = index"
            >
              <input
                name="fifthRadios"
                type="radio"
                [value]="data.value"
                (change)="onRadioChange($event, fifthForm, i)"
              />
              <label>
                {{ data.name }}
              </label>
            </div>
          </div>
          <!-- 
          <p
            class="error"
            *ngIf="this.fifthForm.controls['checkArray'].errors?.required"
          >
            Checkbox is required, select atleast one value.
          </p> -->
          <div class="showNext" *ngIf="showNext">
            <p [innerHTML]="'forth.nextAreaTxt' | translate"></p>
            <div>
              <span [innerHTML]="'forth.letsrate' | translate"></span>
              <span class="highlight">{{
                "forth.nextAreaTitle" | translate
              }}</span>
            </div>
          </div>

          <div class="btnSubmit" *ngIf="!showNext">
            <input
              type="submit"
              value="{{ nextTxt }}"
              (click)="
                progress = 100;
                disabled = false;
                showNext = true;
                onSubmit(fifthForm.value, 5)
              "
            />
          </div>
          <div class="btnNextSection" *ngIf="showNext">
            <input
              type="submit"
              value="{{ nextArea }}"
              (click)="goToNextArea()"
            />
          </div>
        </form>
      </div>
    </div>
    <!-- <div class="totalScore">
      Total Score for first Section: {{ totalScore }}
    </div>
    <button
      [disabled]="disabled"
      type="button"
      class="btn btn-primary mt-3"
      routerLink="/second"
    >
      Next section
    </button> -->
  </div>
</div>
<div class="enzcontainer">
  <div class="progressbar">
    <ngb-progressbar
      type="primary"
      [value]="progress"
      [striped]="false"
    ></ngb-progressbar>
  </div>
</div>
