import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocalServiceGR {
  firstSection: any;
  secondSection: any;
  thirdSection: any;
  forthSection: any;
  fifthSection: any;
  sixthSection: any;
  company = '';
  public firstScore = 0;
  secondScore = 0;
  thirdScore = 0;
  forthScore = 0;
  fifthScore = 0;
  sixthScore = 0;

  rec1: any;
  rec2: any;
  rec3: any;
  rec4: any;
  rec5: any;
  rec6: any;
  fbAnswers = [];
  linkdinAnswrs = [];
  f1 = [];
  f2 = [];
  f3 = [];
  f4 = [];
  f5 = [];
  s1 = [];
  s2 = [];
  s3 = [];
  s4 = [];
  s5 = [];
  t1 = [];
  t2 = [];
  t3 = [];
  t4 = [];
  t5 = [];
  fo1 = [];
  fo2 = [];
  fo3 = [];
  fo4 = [];
  fo5 = [];
  fi1 = [];
  fi2 = [];
  fi3 = [];
  fi4 = [];
  si1 = [];
  si2 = [];
  si3 = [];
  si4 = [];
  constructor() {
    this.firstSection = [
      {
        qtxt: 'Παρουσία στο Web. Δώστε τις πληροφορίες του ιστότοπου της εταιρείας σας. Πόσο καλά είναι οργανωμένα τα προϊόντα/ λύσεις/ υπηρεσίες και οι σχετικές προσφορές περιεχομένου στον ιστότοπό σας; Ο ιστότοπός σας υποστηρίζει την ομάδα πωλήσεών σας  με πλήρη ενημέρωση και διαδραστικό, ενδιαφέρον περιεχόμενο; Επιλέξτε όλα όσα ισχύουν.',
        q: '1a',
        questions: [
          { name: 'Η εταιρεία μου διαθέτει ιστότοπο (website)', value: 20 },
          {
            name: 'Ο ιστότοπος της εταιρείας μου αντιπροσωπεύει την κουλτούρα μας, με ποιοτικό περιεχόμενο - μηνύματα και βάθος περιεχομένου με πλούσια γραφικά, λειτουργική πλοήγηση και σωστή παρουσίαση. Υπάρχουν βασικοί σύνδεσμοι  πλοήγησης για τα προϊοντικές λύσεις και προτάσεις μας. ',
            value: 20,
          },
          {
            name: 'Έχουμε μια μεγάλη ποικιλία περιεχομένου τόσο επιχειρηματικού όσο και τεχνικού όπως άρθρα, ηλεκτρονικά βιβλία, διαδικτυακά σεμινάρια, μελέτες περιπτώσεων και χρήσης σε πελάτες μας, videos οδηγιών-χρήσης κ.α. στον ιστότοπο της εταιρείας μας.',
            value: 20,
          },
          {
            name: 'Στον ιστότοπό μας υπάρχει χρήσιμο περιεχόμενο, συμβουλές ή βασικές συνεργασίες με γνωστούς προμηθευτές / συνεργάτες.  Επίσης ο ιστότοπός μας υποστηρίζει φόρμα εγγραφής με συγκατάθεση αποστολών μέσω email για ενημερωτικά newsletters.',
            value: 20,
          },
          {
            name: 'Ο ιστότοπος της εταιρείας μου διαθέτει ιστολόγιο και ενημερώνεται τακτικά με νέο περιεχόμενο και πληροφορίες σχετικά με τις προσφορές μας. Σχεδόν κάθε σελίδα έχει μια προτροπή σε δράση (CTA: εγγραφή, κλήση, αγορά, άλλη ενέργεια).',
            value: 20,
          },
          { name: 'Δεν ισχύει τίποτα από τα παραπάνω.', value: 0 },
        ],
      },
      {
        qtxt: 'Πόσο σημαντική είναι η παρουσία της εταιρείας σας εκτός internet; Χρησιμοποιεί η εταιρεία σας εκδηλώσεις και συνέδρια για επαφές, δικτύωση και εξωστρέφεια; Επιλέξτε τη δήλωση που περιγράφει καλύτερα την εταιρεία σας:',
        q: '1b',
        questions: [
          {
            name: 'Η οργάνωση εκδηλώσεων (events), η συμμετοχή σε εκθέσεις και συνέδρια τρίτων συμπεριλαμβάνονται στο ετήσιο πλάνο μας.',
            value: 100,
          },
          {
            name: 'Υπάρχει μια καλή παρουσία και πολιτική εξωστρέφειας που ταιριάζει στον κλάδο που δραστηριοποιούμαστε (Οργανώνουμε τουλάχιστον  3 εκδηλώσεις ετησίως και συμμετέχουμε σε τουλάχιστον 2 εκθέσεις ή/και συνέδρια τρίτων).',
            value: 75,
          },
          {
            name: 'Η εταιρεία μου διοργανώνει περιστασιακά κάποιες εκδηλώσεις  και μερικές προσωπικές συναντήσεις με πελάτες και υποψήφιους πελάτες και έχει βασική παρουσία σε συνέδρια τρίτων.',
            value: 50,
          },
          {
            name: 'Η εταιρεία μου σπάνια διοργανώνει εκδηλώσεις και έχει αραιή παρουσία σε συνέδρια, αλλά δεν έχει και συγκεκριμένη στρατηγική ή στόχους σχετικά με το πώς πρέπει να τα χρησιμοποιούμε ως μέρος της στρατηγικής μάρκετινγκ.',
            value: 25,
          },
          { name: 'Δεν ισχύει τίποτα από τα παραπάνω.', value: 0 },
        ],
      },
      {
        qtxt: 'Δώστε πληροφορίες για τις σελίδες κοινωνικών δικτύων της εταιρείας σας και, στη συνέχεια, προσδιορίστε το επίπεδο της δραστηριότητάς της στα μέσα κοινωνικής δικτύωσης αναφέροντας τη δημοτικότητα και τη συχνότητα αναρτήσεων στους λογαριασμούς σας στο LinkedIn και στο Facebook.',
        q: '1c',
        questions: [
          { name: 'Η εταιρεία μου διαθέτει σελίδα στο Facebook.', value: 10 },
          { name: 'Η εταιρεία μου  διαθέτει σελίδα στο LinkedIn.', value: 10 },
        ],
      },
      {
        qtxt: 'Δώστε τις διαδικασίες ή τις λειτουργίες του ιστότοπου που υπάρχουν ή είναι ενεργές για τη δημιουργία δυνητικών πελατών και επισκεψιμότητας. Ποια από τα παρακάτω υπάρχουν στον ιστότοπο της εταιρείας σας; Επιλέξτε όλα όσα ισχύουν.',
        q: '1d',
        questions: [
          {
            name: 'Ένας αριθμός τηλεφώνου ή επιλογές online chat (Messenger / Skype / Whatsapp / Viber ή άλλο) είναι διαθέσιμες στους πελάτες για τη διευκόλυνση των επαφών και των ερωτήσεων.',
            value: 10,
          },
          {
            name: 'Η εταιρεία διαθέτει λογαριασμό Google My Business.',
            value: 10,
          },
          {
            name: 'Ο ιστότοπος της εταιρείας διαθέτει ηλεκτρονική φόρμα επικοινωνίας.',
            value: 10,
          },
          {
            name: 'Διαθέτουμε μηχανισμό που απαντά σε δυνητικούς πελάτες από συμμετοχή σε έκθεση, εκδήλωση, σύσταση συνεργάτη, οργανική αναζήτηση, προστατευμένο περιεχόμενο σε σελίδα του ιστότοπου μας ή από άλλη σχέση.',
            value: 10,
          },
          {
            name: 'Ο ιστότοπος της εταιρείας είναι προσβάσιμος από κινητές συσκευές.',
            value: 10,
          },
          {
            name: 'Η εταιρεία μας διαθέτει μια πλατφόρμα Email/Newsletter για την ενημέρωση και τη δημιουργία περιεχομένου με αυτόματες απαντήσεις ενημέρωσης πελατών στις προσφορές.',
            value: 10,
          },
          {
            name: 'Τα ιστολόγια, άρθρα, βίντεο και άλλα περιεχόμενα του ιστότοπου της εταιρείας μας κοινοποιείται σε πλατφόρμες κοινωνικών μέσων όπως Facebook, LinkedIn  ή Χ.',
            value: 10,
          },
          {
            name: 'Η ηλεκτρονική εγγραφή μετά την επιβεβαίωση του ενδιαφέροντος για επίδειξη ή δοκιμή, μας βοηθά στην απόκτηση υποψήφιου πελάτη ή/και πιθανές αγορές.',
            value: 10,
          },
          {
            name: 'Στον ιστότοπό μας υπάρχει ενεργοποιημένη η ηλεκτρονική παραγγελία και αγορά.',
            value: 20,
          },
          { name: 'Δεν ισχύει τίποτα από τα παραπάνω.', value: 0 },
        ],
      },
      {
        qtxt: 'Αξιολογήστε τη χρήση τεχνικών μάρκετινγκ-διαφήμισης όπως βελτιστοποίηση ιστότοπου για τις μηχανές αναζήτησης  (SEO), Καμπάνιες με χρέωση ανά κλικ, Βίντεο και email. Επιλέξτε όλα όσα ισχύουν.',
        q: '1e',
        questions: [
          {
            name: 'Η εταιρεία μου διαθέτει λογαριασμό Google AdWords και εφαρμόζει ενεργά καμπάνιες με χρέωση ανά κλικ, χρέωση με Λέξεις-κλειδιά ή εμφανίσεις Banners.',
            value: 20,
          },
          { name: 'Η εταιρεία μου διαθέτει κανάλι στο YouTube.', value: 20 },
          {
            name: 'Η εταιρεία μου διαθέτει πλατφόρμα email μάρκετινγκ, μια ενεργή λίστα παραληπτών και στέλνει email τουλάχιστον μία φορά το μήνα. ',
            value: 20,
          },
          {
            name: 'Η εταιρεία μου δημιουργεί περιεχόμενο βίντεο και δημοσιεύει τουλάχιστον 6 φορές το χρόνο.',
            value: 20,
          },
          {
            name: 'Η βελτιστοποίηση της ιστοσελίδας για τις μηχανές αναζητήσεις (SEO) είναι μια στρατηγική προσέγγιση για την εταιρεία μας. ',
            value: 20,
          },
          { name: 'Δεν ισχύει τίποτα από τα παραπάνω.', value: 0 },
        ],
      },
    ];
    this.secondSection = [
      {
        qtxt: 'Αξιολογήστε το επίπεδο της στρατηγικής μάρκετινγκ, του προγραμματισμού και της εφαρμογής της στην εταιρεία σας. Επιλέξτε τη δήλωση που περιγράφει καλύτερα την εταιρεία σας:',
        q: '2a',
        questions: [
          {
            name: 'Το μάρκετινγκ αυξάνει τα έσοδά μας και την αποτελεσματικότητα της εταιρείας μας.',
            value: 100,
          },
          {
            name: 'Το μάρκετινγκ βοηθά στη δημιουργία υποψήφιων πελατών για την εταιρεία.',
            value: 75,
          },
          {
            name: 'Υλοποιούμε συχνά προγραμματισμένες δραστηριότητες μάρκετινγκ προσαρμοσμένες με ψηφιακό περιεχόμενο.',
            value: 50,
          },
          {
            name: 'Υπάρχει ένα όραμα μάρκετινγκ στην εταιρεία μας και τεκμηριωμένα πλάνα μάρκετινγκ.',
            value: 25,
          },
          {
            name: 'Έχουμε απουσία στρατηγικής μάρκετινγκ στην εταιρεία μας.',
            value: 0,
          },
        ],
      },
      {
        qtxt: 'Αξιολογήστε τη δομή και τη διαθεσιμότητα των πόρων μάρκετινγκ στην εταιρεία σας. Έχετε επί του παρόντος εξειδικευμένους εργαζόμενους μάρκετινγκ ή εξωτερική εταιρεία-συνεργάτη μάρκετινγκ; Επιλέξτε τη δήλωση που περιγράφει καλύτερα την εταιρεία σας:',
        q: '2b',
        questions: [
          {
            name: 'Η εταιρεία μου δεν διαθέτει πόρους μάρκετινγκ.',
            value: 0,
          },
          {
            name: 'Η εταιρεία μου έχει εργαζόμενους μερικής απασχόλησης στο μάρκετινγκ ή περιορισμένους πόρους μάρκετινγκ ή οι πόροι αυτοί μοιράζονται και με άλλα τμήματα / λειτουργίες.',
            value: 30,
          },
          {
            name: 'Η εταιρεία μου διαθέτει ένα άτομο (ή μια μικρή ομάδα) ως αποκλειστικούς πόρους μάρκετινγκ.',
            value: 70,
          },
          {
            name: 'Η εταιρεία μου διαθέτει τμήμα μάρκετινγκ (δηλ. αποκλειστικούς πόρους μάρκετινγκ - με περισσότερα από ένα άτομα) και εταιρεία εξωτερικό συνεργάτη (μάρκετινγκ-διαφήμιση).',
            value: 100,
          },
        ],
      },
      {
        qtxt: 'Πώς θα περιγράφατε το επίπεδο δέσμευσης  της εταιρείας σας στο μάρκετινγκ και ειδικά στο ψηφιακό μάρκετινγκ. Αξιολογήστε την υποστήριξη από την ανώτατη διοίκηση. Επιλέξτε τη δήλωση που περιγράφει καλύτερα την εταιρεία σας:',
        q: '2c',
        questions: [
          {
            name: 'Δεν υπάρχει δέσμευση στην εταιρεία μας για το μάρκετινγκ. Δεν υπάρχει υποστήριξη από την Ανώτατη Διοίκηση.',
            value: 0,
          },
          {
            name: 'Όχι με ιδιαίτερη δέσμευση. Η Διοίκηση παρέχει μόνο λεκτική υποστήριξη και ανεπαρκή ουσιαστική υποστήριξη και πόρους.',
            value: 25,
          },
          {
            name: 'Υπάρχει μια σχετική δέσμευση. Υπάρχει μια μέση υποστήριξη της Ανώτατης Διοίκησης και ένα πλάνο.',
            value: 50,
          },
          {
            name: 'Αρκετά έως πολύ δεσμευμένη. Η Ανώτατη Διοίκηση χρηματοδοτεί μερικώς το μάρκετινγκ και υπάρχει ένα πλάνο για μεγαλύτερη επένδυση.',
            value: 75,
          },
          {
            name: 'Η εταιρεία μου είναι πολύ δεσμευμένη με το μάρκετινγκ. Υπάρχει εξαιρετική υποστήριξη από την Ανώτατη Διοίκηση και δέσμευση προϋπολογισμού.',
            value: 100,
          },
        ],
      },
      {
        qtxt: 'Αξιολογήστε τη διαθεσιμότητα και τη χρήση του προγράμματος μάρκετινγκ και άλλων πόρων. Επιλέξτε όλα όσα ισχύουν.',
        q: '2d',
        questions: [
          {
            name: 'Η εταιρεία μου δεν διαθέτει πρόγραμμα μάρκετινγκ, αλλά κάνουμε ορισμένες δραστηριότητες μάρκετινγκ κατά καιρούς.',
            value: 10,
          },
          {
            name: 'Η εταιρεία μας διαθέτει ένα αρχικό πρόγραμμα μάρκετινγκ, αλλά δεν υπάρχει ακόμη εφαρμογή.',
            value: 10,
          },
          {
            name: 'Το τμήμα μάρκετινγκ υποστηρίζει την ομάδα πωλήσεών μας  με υλικό, πληροφορίες και διαδραστικό, ενδιαφέρον περιεχόμενο.',
            value: 20,
          },
          {
            name: 'Υπάρχει γνώση του κλάδου μας και των ανταγωνιστών. Υπάρχει μια ανάλυση ανταγωνισμού της εταιρείας μας ως πρoς το μάρκετινγκ και τη δημιουργία υποψηφίων πελατών και της απόκτησης τους.',
            value: 20,
          },
          {
            name: 'Το τμήμα μάρκετινγκ διαθέτει αρκετά εργαλεία μάρκετινγκ (λογισμικό) και εξωτερικούς συνεργάτες & πόρους για την υλοποίηση εκστρατειών μάρκετινγκ.',
            value: 20,
          },
          {
            name: 'Υπάρχει ένας συγκεκριμένος προϋπολογισμός για επενδύσεις σε δραστηριότητες μάρκετινγκ που φέρνουν ανάπτυξη.',
            value: 20,
          },
          {
            name: 'Δεν ισχύει τίποτα από τα παραπάνω',
            value: 0,
          },
        ],
      },
      {
        qtxt: 'Αξιολογήστε τα διάφορα εργαλεία και συστήματα  μάρκετινγκ της εταιρείας σας για να υποστηρίξετε τους υπάρχοντες πελάτες και την απόκτηση νέων πελατών. Επιλέξτε τη δήλωση που περιγράφει καλύτερα την εταιρεία σας:',
        q: '2e',
        questions: [
          {
            name: 'Κάνουμε μάρκετινγκ προσανατολισμένο στις διαπροσωπικές σχέσεις, δια-ζώσης επαφές και δικτύωση με απόκτηση νέων επαφών κυρίως μέσω εκδηλώσεων. Η χρήση ψηφιακών και κοινωνικών δικτύων επικοινωνίας βρίσκεται στο χαμηλότερο επίπεδο.',
            value: 25,
          },
          {
            name: 'Διατηρούμε μια παραδοσιακή προσέγγιση για τις σχέσεις, τη δέσμευση και επικοινωνία με πελάτες όπως εκδηλώσεις, κίνητρα πωλήσεων και παραδοσιακά μαζικά μέσα επικοινωνίας (πχ τύπος), που εξακολουθούν να αποτελούν το κύριο κανάλι ενώ σιγά-σιγά αρχίζουμε και να χρησιμοποιούμε και τα ψηφιακά μέσα.',
            value: 50,
          },
          {
            name: 'Χρησιμοποιούμε εξίσου τα παραδοσιακά και ψηφιακά κανάλια / εργαλεία για να υποστηρίξουμε τις πωλήσεις και τις σχέσεις με τους ήδη υπάρχοντες πελάτες αλλά και να προσελκύσουμε υποψήφιους πελάτες και να φέρουμε νέες δουλειές.',
            value: 75,
          },
          {
            name: 'Στην εταιρεία χρησιμοποιούμε κυρίως τις ψηφιακές τεχνικές (emails- σελίδες προορισμού) και η επικοινωνία με μέσα κοινωνικής δικτύωσης είναι απαραίτητο εργαλείο για την ενδυνάμωση νέων σχέσεων και νέων επιχειρηματικών μοντέλων.',
            value: 100,
          },
        ],
      },
    ];
    this.thirdSection = [
      {
        qtxt: 'Βαθμολογήστε τη δική σας Πρόταση Αξίας Πελάτη  έναντι των ανταγωνιστών σας.',
        q: '3a',
        questions: [
          {
            name: 'Η εταιρεία μας έχει μια καλά καθορισμένη Πρόταση Αξίας Πελάτη (CVP).',
            value: 25,
          },
          {
            name: 'Η Πρόταση Αξίας Πελάτη της εταιρείας είναι επιθυμητή (ανταποκρίνεται στις ανάγκες των πελατών).',
            value: 25,
          },
          {
            name: 'Η Πρόταση Αξίας Πελάτη της εταιρείας μας είναι διακριτή (με κάποιο τρόπο ξεχωρίζει από τις αντίστοιχες CVP άλλων εταιριών).',
            value: 25,
          },
          {
            name: 'Η Πρόταση Αξίας  για τον Πελάτη μας, είναι αιτιολογημένη και αποδεικνυόμενη (έχουμε «αποδεικτικά στοιχεία»  ότι θα κάνει αυτό που λέμε, υποσχόμαστε).',
            value: 25,
          },
        ],
      },
      {
        qtxt: 'Αξιολογήστε τη θέση/τοποθέτησή σας στην αγορά  (positioning) και το επίπεδο ισχύος του δικού σας μάρκετινγκ έναντι των ανταγωνιστών σας.',
        q: '3b',
        questions: [
          {
            name: 'Η εταιρεία μας έχει μια καλή τοποθέτηση στο μυαλό των πελατών  στην αγορά (positioning).',
            value: 25,
          },
          {
            name: 'Η εταιρεία (ή το brand) μας είναι διαφορετική και διακριτή έναντι των τριών βασικών ανταγωνιστών μας (Σημ.: η διαφοροποίηση αφορά στη μεγιστοποίηση της αντιληπτής-διακριτής διαφοράς μεταξύ ημών και των ανταγωνιστών μας).',
            value: 25,
          },
          {
            name: 'Η τοποθέτηση (positioning) μας συνδέεται με βασικές αξίες (για τον κλάδο) και έχει μια σαφή στρατηγική μηνυμάτων που επικοινωνεί.',
            value: 25,
          },
          {
            name: 'Η καλά καθορισμένη Πρόταση Αξίας για τον Πελάτη μας έχει τον παράγοντα της συνάφειας-σχετικότητας. (Η συνάφεια αφορά τη μεγιστοποίηση της αντιληπτής ταύτισης-"δεσίματος" της εταιρείας/brand μας από τη μια και των αναγκών των πελατών μας από την άλλη).',
            value: 25,
          },
        ],
      },
      {
        qtxt: 'Αξιολογήστε και βαθμολογήστε τη δημιουργία και παραγωγή περιεχομένου μάρκετινγκ της εταιρείας σας και την αποτελεσματικότητά του. Επιλέξτε τη δήλωση που περιγράφει καλύτερα την εταιρεία σας:',
        q: '3c',
        questions: [
          {
            name: 'Ελάχιστα αποτελεσματική. Το περιεχόμενο δημιουργείται σπάνια ή σε ad hoc βάση. Χρησιμοποιούμε περιεχόμενο από τους συνεργάτες μας-προμηθευτές μας (π.χ. έχουμε πρόσβαση στα εργαλεία συγχρονισμού περιεχομένου μάρκετινγκ των προμηθευτών μας).',
            value: 25,
          },
          {
            name: 'Μετρίως αποτελεσματική. Δημιουργούμε βασικό περιεχόμενο όπως eNewsletters, αναρτήσεις, άρθρα-blogs, μελέτες περιπτώσεων, διαδικτυακά σεμινάρια) που παράγονται κυρίως για καμπάνιες και τακτικές δραστηριότητες (και περιεχόμενο τρίτων από συνεργάτες / προμηθευτές, εάν εφαρμόζεται).',
            value: 50,
          },
          {
            name: 'Αποτελεσματική. Έχει δημιουργηθεί ένα απόθεμα του νέου περιεχομένου το οποίο μαζί με το περιεχόμενο τρίτων από συνεργάτες/προμηθευτές  χρησιμοποιείται για την προώθησή μας και τη συνεχή ανάπτυξή μας.',
            value: 75,
          },
          {
            name: 'Εξαιρετικά αποτελεσματική. Δημιουργούμε πρωτότυπο ελκυστικό περιεχόμενο και χαρτογραφούμε το περιεχόμενο ανά στάδιο της αγοραστικής διαδρομής του πελάτη και τις περσόνες αγορών [για παράδειγμα, με καθορισμό στόχων περιεχομένου (ηγεσία σκέψης, συνειδητοποίηση, επιβεβαίωση αξίας κ.λπ.) χρησιμοποιώντας βασικές μετρήσεις για αξιολόγηση (π.χ. επισκεψιμότητα, κοινοποιήσεις, λήψεις, μετατροπές, κατανομή εσόδων)].',
            value: 100,
          },
        ],
      },
      {
        qtxt: 'Αξιολογήστε τη χαρτογράφηση της Αγοραστικής Διαδρομής των πελατών σας σχετικά με τον τρόπο που ευθυγραμμίζεται το περιεχόμενό σας και της αποτελεσματικότητάς του για την αύξηση των πωλήσεων. Επιλέξτε τη δήλωση που περιγράφει καλύτερα την εταιρεία σας:',
        q: '3d',
        questions: [
          {
            name: 'Δεν υπάρχει καταγεγραμμένη αγοραστική διαδρομή πελατών.',
            value: 25,
          },
          {
            name: 'Έχει καταγραφεί η αγοραστική διαδρομή πελάτών με προσδιορισμό βασικών φάσεων (χωρίς να υπάρχει η αποδοχή της ομάδας πωλήσεων).',
            value: 50,
          },
          {
            name: 'Καταγράψαμε μια καλά καθορισμένη αγοραστική διαδρομή πελατών με τη χαρτογράφηση του περιεχομένου να αξιοποιείται τόσο από την ομάδα μάρκετινγκ όσο και από την ομάδα πωλήσεων.',
            value: 75,
          },
          {
            name: 'Διαθέτουμε μια πλήρως δομημένη, ακριβώς καθορισμένη αγοραστική διαδρομή πελατών, χαρτογραφημένη με το κατάλληλο περιεχόμενο σε κάθε στάδιο, οδηγώντας έτσι τις πωλήσεις στην εταιρεία μας.',
            value: 100,
          },
        ],
      },
      {
        qtxt: 'Πώς θα αξιολογούσατε τη δύναμη της στρατηγικής σας σε καθέναν από αυτούς τους τομείς:',
        q: '3e',
        questions: [
          {
            name: 'Τεχνολογίες & Αυτοματισμοί (Marketing Automation) / Τεχνολογίες και δυνατότητες χρήσης τους (MarTech capabilities).',
            value: 20,
          },
          {
            name: 'Ψηφιακή Διαφήμιση (Digital).',
            value: 20,
          },
          {
            name: 'Άμεσο Μάρκετινγκ και Email (Direct  & Email Marketing).',
            value: 20,
          },
          {
            name: 'Μάρκετινγκ εκδηλώσεων (events).',
            value: 20,
          },
          {
            name: 'Μάρκετινγκ Κοινωνικών Δικτύων (Social Media).',
            value: 20,
          },
        ],
      },
    ];
    this.forthSection = [
      {
        qtxt: 'Αξιολογήστε την αξία της δικής σας βάσης δεδομένων μάρκετινγκ που μπορεί να οδηγήσει σε τμηματοποίηση κοινού και αποτελεσματικότητα σε καμπάνιες. Επιλέξτε τη δήλωση που περιγράφει καλύτερα την εταιρεία σας:',
        q: '4a',
        questions: [
          {
            name: 'Χρησιμοποιούμε μια περιορισμένη βάση δεδομένων μάρκετινγκ (λίστα offline από παλιά emails κ.λπ.)',
            value: 25,
          },
          {
            name: 'Έχουμε δημιουργήσει μια κεντρική βάση δεδομένων (χαμηλού μεγέθους και ποιότητας) με βασικά τμήματα πελατών.',
            value: 50,
          },
          {
            name: 'Έχουμε δημιουργήσει μια τμηματοποιημένη βάση δεδομένων (μεσαίου μεγέθους και ποιότητας), ικανή να εντοπίζει πιθανούς στόχους για αύξηση πωλήσεων ή και συμπληρωματικές πωλήσεις.',
            value: 75,
          },
          {
            name: 'Έχει δημιουργηθεί μια υψηλής ποιότητας προηγμένη τμηματοποιημένη βάση δεδομένων που βασίζεται σε B2B δημογραφικά στοιχεία, δεδομένα συμπεριφοράς, ανάγκες και ιστορικά δεδομένα για την υποστήριξη συνολικά της αγοραστικής διαδρομής του πελάτη.',
            value: 100,
          },
        ],
      },
      {
        qtxt: 'Αξιολογήστε τις δυνατότητες της πλατφόρμας Διαχείρισης Σχέσεων των Πελατών σας καθώς και τις διαδικασίες  διαχείρισης πελατών-συνεργατών και εταιρικών λογαριασμών στην εταιρεία σας. Επιλέξτε τη δήλωση που περιγράφει καλύτερα την εταιρεία σας:',
        q: '4b',
        questions: [
          {
            name: 'Δεν υπάρχει κεντρική πλατφόρμα CRM. Οι επαφές πωλήσεων, η φάση της διαδικασίας πώλησης και η δρομολόγησή της πραγματοποιούνται μέσω διαφόρων ή διαφορετικών εφαρμογών.',
            value: 25,
          },
          {
            name: 'Έχουμε δημιουργήσει μια βάση δεδομένων επαφών, παρόλο που οι διαδικασίες και οι μετρήσεις είναι κυρίως κατά παραγγελία.',
            value: 50,
          },
          {
            name: 'Διαθέτουμε οργανωμένη πλατφόρμα CRM με σαφώς καθορισμένες διαδικασίες και εποπτεύουμε και παρακολουθούμε τους δείκτες απόδοσης',
            value: 75,
          },
          {
            name: 'Η πλατφόρμα CRM που διαθέτουμε έχει οργανωθεί, με ενσωματωμένα πολύτιμα στοιχεία από την εμπειρία των πελατών της εταιρείας. Το CRM σύστημά μας λειτουργεί καλά, παρέχοντας τακτικές ενημερώσεις των δεδομένων πελατών.',
            value: 100,
          },
        ],
      },
      {
        qtxt: 'Αξιολογήστε τη διαδικασία που εφαρμόζετε για τη δημιουργία-δρομολόγηση των υποψηφίων επαφών και την απόκτηση πελατών. Επιλέξτε τη δήλωση που περιγράφει καλύτερα την εταιρεία σας:',
        q: '4c',
        questions: [
          {
            name: 'Περιορισμένη. Δεν έχουμε καθορίσει κριτήρια για την αξιολόγηση των υποψηφίων πελατών.',
            value: 25,
          },
          {
            name: 'Σχετικά επαρκής με στοχοποίηση και καθορισμό επαφών και υποψηφίων. Οι διαδικασίες "καλλιέργειας" των επαφών και απόκτησης των υποψηφίων πελατών είναι καθορισμένες αλλά δεν χρησιμοποιούνται ευρέως.',
            value: 50,
          },
          {
            name: 'Καλή. Οι υποψήφιοι πελάτες δημιουργούνται, αξιολογούνται και υπολογίζονται καθ’ όλη τη διαδικασία πωλήσεων. Στοιχεία και δεδομένα όπως το Μέσο Μέγεθος Συμφωνίας/Πώλησης κατά τη δημιουργία ευκαιριών έναντι του ποσοστού κλεισίματος ευκαιριών ή Διαχρονική Αξία Πελάτη (CLV) είναι διαθέσιμα.',
            value: 75,
          },
          {
            name: 'Εξαιρετική. Οι υποψήφιοι πελάτες υπολογίζονται και μετριούνται στο λογισμικό αυτοματοποίησης μάρκετινγκ (Marketing Automation Platform), και αυτό ενισχύεται με δεδομένα από άλλα ψηφιακά και κοινωνικά δίκτυα και εκδηλώσεις, διαδικτυακά σεμινάρια κ.λπ.',
            value: 100,
          },
        ],
      },
      {
        qtxt: 'Αξιολογήστε και βαθμολογήστε τον τρόπο διαχείρισης του αγωγού σας (pipeline) σε όλες τις πωλήσεις και το μάρκετινγκ; Επιλέξτε τη φράση που περιγράφει καλύτερα την εταιρεία σας:',
        q: '4d',
        questions: [
          {
            name: 'Περιορισμένη εξέλιξη αγωγού. Ανιμετωπίζουμε τον αγωγό μάρκετινγκ και πωλήσεων (pipeline) με προσαρμοσμένα εργαλεία, χωρίς τη χρήση πλατφόρμας CRM.',
            value: 25,
          },
          {
            name: 'Μέτρια. Οι αναφορές pipeline βασίζονται στη βάση δεδομένων CRM, χωρίς μεγάλη ακρίβεια λόγω της περιορισμένης ποιότητας των δεδομένων ή / και της έλλειψης σαφώς καθορισμένων μετρήσεων.',
            value: 50,
          },
          {
            name: 'Επαρκής. Οι αναφορές του pipeline μας (αγωγού), περιλαμβάνουν δεδομένα υψηλής ποιότητας, παρόλο που δεν υπάρχουν συνεχείς ενημερώσεις και η παρακολούθηση των Βασικών Δεικτών Απόδοσης (KPIs) πραγματοποιείται σε εξατομικευμένη βάση. ',
            value: 75,
          },
          {
            name: "Εξαιρετική. Η αναφορά του αγωγού μας (pipeline reporting) ενημερώνεται αυτόματα και ορίζεται επαρκώς καθ 'όλη τη διαδικασία πωλήσεων, οδηγώντας τις δραστηριότητες της ομάδας μας σε βέλτιστα επιχειρηματικά αποτελέσματα.",
            value: 100,
          },
        ],
      },
      {
        qtxt: 'Αξιολογήστε και βαθμολογήστε πόσο καλά ευθυγραμμίζονται  οι ομάδες Πωλήσεων και Μάρκετινγκ σχετικά με ό,τι αποτελεί "Αναγνωρισμένο Υποψήφιο Πελάτη" και την ευθυγράμμισή τους για την ανάπτυξη. Επιλέξτε τη φράση που περιγράφει καλύτερα την εταιρεία σας:',
        q: '4e',
        questions: [
          {
            name: 'Ασυμφωνία. Στην εταιρεία μας κατανοούμε τα χαρακτηριστικά ενός ποιοτικού "Αναγνωρισμένου Υποψήφιου Πελάτη", αλλά υπάρχουν συγκρούσεις των ομάδων Πωλήσεων και Μάρκετινγκ και η συνεργασία μεταξύ τους δεν είναι καλή.',
            value: 25,
          },
          {
            name: 'Xαμηλός δείκτης ευθυγράμμισης. Οι Ομάδες Πωλήσεων συχνά παραπονιούνται ότι οι υποψήφιοι πελάτες δεν είναι αρκετά καλοί. Οι ομάδες μάρκετινγκ δεν γνωρίζουν ποια στοιχεία και υλικά μάρκετινγκ είναι πιο χρήσιμα για τους συναδέλφους τους των πωλήσεων. Απαιτούνται περισσότερες προσπάθειες για να έρθουν αποτελέσματα από την ευθυγράμμιση Πωλήσεων-Μάρκετινγκ.',
            value: 50,
          },
          {
            name: 'Σχετική ευθυγράμμιση. Και οι δύο ομάδες προσπαθούν να ευθυγραμμιστούν και ο ρυθμός ανάπτυξης και η αύξηση των εσόδων δείχνουν ότι είναι πρόθυμοι και το επιδιώκουν.',
            value: 75,
          },
          {
            name: 'Πλήρης ευθυγράμμιση. Και οι δύο ομάδες έχουν κοινούς στόχους και καταγεγραμμένες περιγραφές- οριοθετημένες ενέργειες [π.χ. τι είναι το MQL (Marketing Qualified Lead), μια αξιόλογη επαφή]. Οι ομάδες είναι πλήρως ευθυγραμμισμένες και η εταιρεία κερδίζει υψηλότερη αξία παραγγελίας από  υποψήφιους πελάτες που "καλλιεργούνται-φροντίζονται" μέσω των διαδικασιών μάρκετινγκ-πωλήσεων.',
            value: 100,
          },
        ],
      },
    ];
    this.fifthSection = [
      {
        qtxt: 'Αποτυπώστε τον τρόπο οργάνωσης των δεδομένων και αναλύσεων μάρκετινγκ που διαθέτετε. Επιλέξτε τη δήλωση που περιγράφει καλύτερα την εταιρεία σας:',
        q: '5a',
        questions: [
          {
            name: 'Υπάρχει περιορισμένη χρήση αναλυτικών στοιχείων στην εταιρεία μας (για παράδειγμα, βασικές αναφορές ηλεκτρονικού ταχυδρομείου (email reporting)  ή αναλυτικά στοιχεία ισότοπου (web analytics)).',
            value: 25,
          },
          {
            name: 'Η υποστήριξη ad hoc Analytics παρέχεται από εξωτερικό προμηθευτή σε τακτική βάση.',
            value: 50,
          },
          {
            name: 'Τα Δεδομένα και τα Στοιχεία Αναλύσεων από συγκρίσιμες δραστηριότητες (όπως μια σειρά προηγούμενων καμπανιών) υποβάλλονται σε επεξεργασία για την αξιολόγηση της αποτελεσματικότητας του μάρκετινγκ, χωρίς να προσδιορίζεται η επιχειρηματική στρατηγική της επιχείρησης.',
            value: 75,
          },
          {
            name: 'Οι Αναλύσεις και τα Δεδομένα μάρκετινγκ έχουν βαθιά επίδραση στον προγραμματισμό και τη λήψη αποφάσεων της εταιρείας μας, σε όλα τα κανάλια μάρκετινγκ.',
            value: 100,
          },
        ],
      },
      {
        qtxt: 'Αποτυπώστε το πλαίσιο και την οργάνωση, "στήσιμο" των μετρήσεων μάρκετινγκ και της εμπειρίας των πελατών της εταιρείας σας και τη χρήση για υποστήριξη αποφάσεων. Επιλέξτε όλα όσα ισχύουν.',
        q: '5b',
        questions: [
          {
            name: 'Πραγματοποιούμε Μετρήσεις Αφοσίωσης των πελατών όπως NPS (Net Promoter Score), ικανοποίηση πελατών, πιθανότητα αγοράς, προσπάθεια πελατών για την αγορά κ.λπ.',
            value: 25,
          },
          {
            name: 'Διεξάγουμε Μετρήσεις Ποιότητας όπως ποσοστά αποτυχίας προϊόντος, διάρκεια ζωής προϊόντος, κόστος συντήρησης κ.λπ.',
            value: 25,
          },
          {
            name: 'Πραγματοποιούμε Μετρήσεις Κόστους εκστρατειών και Αποτελεσματικότητας, όπως δείκτες κόστους, κόστος ανά υποψήφιο πελάτη, εμφανίσεις έναντι μέσων όρων του κλάδου, κόστος απόκτησης νέων πελατών κ.λπ.',
            value: 25,
          },
          {
            name: 'Κάνουμε σημαντικές μετρήσεις και έρευνες για πελάτες, όπως έγκαιρες παραδόσεις, ταχύτητα απόκρισης σε ερωτήματα, ταχύτητα απόκρισης σε καταγγελίες κ.λπ.',
            value: 25,
          },
          {
            name: 'Δεν κάνουμε τίποτα από τα παραπάνω.',
            value: 0,
          },
        ],
      },
      {
        qtxt: "Αξιολογήστε πόσο καλά έχετε καθορίσει τους στόχους μάρκετινγκ και προσδιορίστε πώς θα μετρηθούν τα KPI's (επιτυχία-αποτυχία). Επιλέξτε τη δήλωση που περιγράφει καλύτερα την εταιρεία σας:",
        q: '5c',
        questions: [
          {
            name: "Οι Στόχοι μας και οι Βασικοί Δείκτες Απόδοσης (KPI's) δεν προσδιορίζονται σωστά.",
            value: 25,
          },
          {
            name: "Εστιάζουμε σε Βασικούς Δείκτες Απόδοσης (KPI's)  μόνο σε βραχυπρόθεσμες καμπάνιες.",
            value: 50,
          },
          {
            name: 'Εστιάζουμε σε έναν συνδυασμό μετρήσεων που σχετίζονται τόσο με καμπάνιες όσο και με πελάτες με βάση ένα πλάνο και αναλύουμε τακτικά τα δεδομένα μας για τη μέτρηση της αποτελεσματικότητας.',
            value: 75,
          },
          {
            name: "Χρησιμοποιούμε μια κάρτα αποτελεσμάτων (ένα ScoreCard) για να τραβήξουμε πηγές δεδομένων σε έναν πίνακα ελέγχου (dashboard) για παρακολούθηση και ανάλυση και αναπτύσσουμε διάφορες μετρήσεις ως KPI's.",
            value: 100,
          },
        ],
      },
      {
        qtxt: 'Ελέγξτε τη διαθεσιμότητα και τη χρήση των τεχνολογιών μάρκετινγκ που έχετε για να αυξήσετε την απόδοση. Επιλέξτε όλα όσα ισχύουν.',
        q: '5d',
        questions: [
          {
            name: 'Επαφές. Βάσεις δεδομένων και λίστες υποψηφίων πελατών - εργαλεία ενεργοποίησης πωλήσεων.',
            value: 20,
          },
          {
            name: 'Συνδέσεις - Επισκέπτες. Κοινωνικά δίκτυα, ιστότοπος, email, διαδραστικό λογισμικό και εργαλεία επαναληπτικού μάρκετινγκ.',
            value: 20,
          },
          {
            name: 'Περιεχόμενο. Εργαλεία και λογισμικό για δημιουργία περιεχομένου, βελτιστοποίηση και βαθμολόγηση.',
            value: 20,
          },
          {
            name: 'Στοιχεία πελατών. Λύσεις λειτουργίας GoToMarket και λογισμικό διαχείρισης σχέσεων και υπηρεσιών ανάλυσης δραστηριοτήτων πελάτη.',
            value: 20,
          },
          {
            name: 'Χρήση πλατφόρμας Αυτοματισμών Μάρκετινγκ.',
            value: 20,
          },
        ],
      },
    ];
    this.sixthSection = [
      {
        qtxt: 'Αξιολογήστε πώς υλοποιείτε το πρόγραμμα για τις καμπάνιες της εταιρείας σας και αξιολογήστε τις φάσεις που ορίζονται τακτικά στο πλάνο κάθε καμπάνιας μάρκετινγκ. Επιλέξτε όλα όσα ισχύουν για την εταιρεία σας.',
        q: '6a',
        questions: [
          {
            name: 'Ανάλυση-Αξιολόγηση. Επιχειρηματικές ανάγκες, προηγμένη τμηματοποίηση με χαρτογράφηση της αγοράς, προσδιορισμός βασικών κριτηρίων απόφασης αγοράς και αναγνώριση τμημάτων αγοράς προς στόχευση, πληροφορίες και ιδέες. Καθορισμός της στρατηγικής, των προτεραιοτήτων και των ενεργειών για την προσέγγιση του αγοραστή.',
            value: 15,
            id: 'inlineRadio1',
          },
          {
            name: "Καθορισμός Στόχων. Επιχειρηματικοί στόχοι και στόχοι καμπάνιας και Βασικοί Δείκτες Απόδοσης (KPI's)/ (Τι προσπαθείτε να πετύχετε μέσω της καμπάνιας σας και πώς θα ξέρετε πότε θα το πετύχετε;)",
            value: 15,
            id: 'inlineRadio2',
          },
          {
            name: 'Περιεχόμενο. Δημιουργικά και Βασικά μηνύματα. (Πώς προσπαθείτε να τοποθετήσετε την εταιρεία, τα προϊόντα και τις υπηρεσίες σας). Παραγωγή Περιεχομένου και Υλικών καμπάνιας. (Διαχείριση περιεχομένου και υλικού μάρκετινγκ για τη διαμόρφωση της καμπάνιας).',
            value: 15,
            id: 'inlineRadio3',
          },
          {
            name: 'Στόχευση. Οι καμπάνιες είναι σχεδιασμένες για την αντιμετώπιση συγκεκριμένων γεωγραφικών περιοχών, βιομηχανιών, αναπτυσσόμενων αγοραστικών τμημάτων, υπαρχόντων πελατών και καναλιών. Η υπερ-στόχευση χρησιμοποιείται για στόχευση καμπάνιας (π.χ. με ενοποιημένη βάση δεδομένων CRM, μηχανισμούς βαθμολόγησης και προηγμένα προφίλ υποψήφιων αγοραστών-πελατών).',
            value: 15,
            id: 'inlineRadio3',
          },
          {
            name: 'Σχεδιασμός και προϋπολογισμός Μέσων καμπάνιας. (Ποια κανάλια μέσων θα χρησιμοποιήσετε για να προσεγγίσετε και να επηρεάσετε το κοινό-στόχο σας;)',
            value: 20,
            id: 'inlineRadio3',
          },
          {
            name: 'Εκτέλεση. (Τι πρέπει να δοκιμαστεί πριν από την έναρξη-προβολή της καμπάνιας και τι προσαρμογές-διορθώσεις πρέπει να γίνουν;)',
            value: 10,
            id: 'inlineRadio3',
          },
          {
            name: 'Παρακολούθηση. Αξιολόγηση μετά την εκτέλεση. Πραγματοποιήστε μια ανάλυση καμπάνιας και τροποποιήστε τη για να βελτιώσετε τη συνολική απόδοση εάν χρειάζεται.',
            value: 10,
            id: 'inlineRadio3',
          },
          {
            name: 'Δεν ισχύει τίποτα από τα παραπάνω.',
            value: 0,
            id: 'inlineRadio3',
          },
        ],
      },
      {
        qtxt: 'Αξιολογήστε το επίπεδο του εσωτερικού περιβάλλοντος (δυνάμεις & αδυναμίες) και του εξωτερικού περιβάλλοντος (απειλές-ευκαιρίες) για την εταιρεία σας. Επιλέξτε τη δήλωση που περιγράφει καλύτερα την εταιρεία σας:',
        q: '6b',
        questions: [
          {
            name: 'Δεν καταγράφεται SWOT ή ανταγωνιστική ανάλυση.',
            value: 25,
            id: 'inlineRadio4',
          },
          {
            name: 'Πραγματοποιούμε ανάλυση SWOT αυτή την περίοδο.',
            value: 50,
            id: 'inlineRadio5',
          },
          {
            name: 'Έχουμε κάνει ήδη  ανάλυση SWOT για την εταιρεία. ',
            value: 75,
            id: 'inlineRadio6',
          },
          {
            name: 'Η εταιρεία διαθέτει ανάλυση SWOT μαζί με άλλα ευρήματα έρευνας για χρήση στη διαδικασία προγραμματισμού μάρκετινγκ.',
            value: 100,
            id: 'inlineRadio7',
          },
        ],
      },
      {
        qtxt: 'Ελέγξτε και αξιολογήστε την αξία και την εμπορευσιμότητα-ελκυστικότητα, δυνατότητα πώλησης των προϊόντων / υπηρεσιών σας. Επιλέξτε όλα όσα ισχύουν.',
        q: '6c',
        questions: [
          {
            name: 'Η εταιρεία μας διαθέτει προϊόντα/υπηρεσίες που έχουν μοναδικά-ιδιαίτερα χαρακτηριστικά που βοηθούν να ξεχωρίζουν ή να ανταγωνίζονται σε σχετικές τιμές συγκριτικά με την αντιληπτή ποιότητα.',
            value: 20,
          },
          {
            name: 'Διαθέτουμε προϊόντα/υπηρεσίες που οι πελάτες έχουν κίνητρα να αγοράσουν επειδή ικανοποιούν τις ανάγκες τους.',
            value: 20,
          },
          {
            name: 'Η εταιρεία μας έχει καθορίσει το Ιδανικό Προφίλ Πελάτη (ICP) και τα προφίλ-περσόνες αγοραστή όπως και τις απαντήσεις σε ερωτήματα "τι είναι-για ποιον είναι".',
            value: 20,
          },
          {
            name: 'Η αξία των προσφορών μας στον πελάτη περιλαμβάνει ποιοτικούς παράγοντες όπως η αξιοπιστία, η εμπειρία πελάτη, η παραγωγικότητα, η διαθεσιμότητα και η χρηστικότητα κ.λπ.',
            value: 20,
          },
          {
            name: 'Τα προϊόντα/υπηρεσίες μας μπορούν να βοηθήσουν τους πελάτες να λύσουν ένα πρόβλημα που κάνει τη ζωή ή την εργασία τους πιο βολική, πιο εύκολη, πιο άνετη.',
            value: 20,
          },
          {
            name: 'Δεν ισχύει τίποτα από τα ανωτέρω.',
            value: 0,
          },
        ],
      },
      {
        qtxt: 'Αναλύστε την προσέγγιση προϋπολογισμού της εταιρείας σας και τις προτεραιότητές της στην επένδυση μάρκετινγκ.Ποιο από αυτά είναι τακτικά σημαντικό και χρησιμοποιείται στον προϋπολογισμό μάρκετινγκ; Επιλέξτε όλα όσα ισχύουν.',
        q: '6d',
        questions: [
          {
            name: 'Ιστότοπος (Website).            ',
            value: 20,
          },
          {
            name: 'Διαφημιστικά Μέσα επί πληρωμή (Digital, Google κ.α).',
            value: 15,
          },
          {
            name: 'Κοινωνικά Δίκτυα (Social Media).',
            value: 15,
          },
          {
            name: 'Δημιουργία και Παραγωγή Περιεχομένου (Content Creation).',
            value: 10,
          },
          {
            name: 'Εκδηλώσεις, Συνέδρια, Σεμινάρια, Εκπαιδεύσεις (Events/ Conferences/ Webinars).',
            value: 10,
          },
          {
            name: 'Τεχνολογίες Μάρκετινγκ και Λογισμικά (Marketing Technology).',
            value: 10,
          },
          {
            name: 'Κίνητρα Πωλήσεων και άλλα προωθητικά κίνητρα (Sales Incentives).',
            value: 10,
          },
          {
            name: 'Εταιρική και Brand επικοινωνία (EKE κλπ) Corporate / Brand Building).',
            value: 10,
          },
        ],
      },
    ];

    this.rec1 = [
      'Ξεκινήστε να φροντίζετε περισσότερο την παρουσία της εταιρείας σας με στόχο  να την κάνετε πιο εξωστρεφή. Χρησιμοποιήστε περιεχόμενο και στοιχεία μάρκετινγκ από τους συνεργάτες /προμηθευτές σας και δημιουργήστε το δικό σας περιεχόμενο στα κοινωνικά δίκτυα που θα σας βοηθήσει αργά να δημιουργήσετε τα δικά σας κανάλια επικοινωνίας (αν δεν έχετε). Επίσης, σκεφτείτε να οργανώσετε δια ζώσης ή ψηφιακές εκδηλώσεις για πελάτες και υποψήφιους πελάτες.',
      'Αναπτύξτε περαιτέρω την ταυτότητα του brandname σας, εμπλουτίζοντας το περιεχόμενο του website σας κάνοντας καμπάνιες,  διοργανώνοντας επιτυχημένες εκδηλώσεις (events), για να καλύψετε τις ανάγκες επικοινωνίας της επιχείρησής σας με τους πελάτες. Αναπτύξτε ένα πλάνο μάρκετινγκ που ταιριάζει και αντικατοπτρίζει την επιχείρησή σας βελτιώνοντας το brand σας, τα προϊόντα /τις λύσεις σας και επικεντρώνοντας στην Πρόταση Αξίας στον Πελάτη.',
      'Έχετε μια αξιοπρεπή εξωστρεφή παρουσία μάρκετινγκ που πρέπει να αναπτύξετε περαιτέρω εξερευνώντας λύσεις που κάνουν την εταιρεία σας "πιο ψηφιακή" και να ενισχύσετε τα προϊόντα σας, το κανάλι διανομής και το περιεχόμενο μάρκετινγκ (για παράδειγμα συγχρονισμό του περιεχομένου (content syndication) με τους βασικούς συνεργάτες/προμηθευτές σας). Αναζητήστε χρήσιμες λύσεις και εφαρμογές για τις δυνατότητες σας ή συζητήστε με την εταιρεία μάρκετινγκ που σας υποστηρίζει την περαιτέρω ανάπτυξή σας στο μάρκετινγκ.',
      'Ενισχύστε την ήδη πολύ δυναμική μάρκετινγκ παρουσία σας, δημιουργώντας το δικό σας αυθεντικό περιεχόμενο που θα προσθέσει αξία στους πελάτες σας και θα διερευνήσει τη βαθύτερη τμηματοποίηση των χρηστών/πελατών σας, έτσι ώστε να μπορείτε να προσαρμόσετε το περιεχόμενο σας στις ανάγκες και τις ανάγκες του αγοραστή. Οι τεχνολογίες μάρκετινγκ και μια συνεπής στρατηγική ανάπτυξης που θα εφαρμόσετε θα ενδυναμώνουν τις λειτουργίες που αφορούν το μάρκετινγκ ώστε να υπερισχύετε των ανταγωνιστών σας και να εξασφαλίζετε  ανταγωνιστικό  πλεονέκτημα.',
    ];
    this.rec2 = [
      'Ο σχεδιασμός και η οργάνωση του τμήματος μάρκετινγκ (ή νέας εταιρείας) θα βοηθήσουν να λάβετε τα σωστά μέτρα για να βελτιώσετε τη θέση σας στην αγορά. Επωφεληθείτε από τα δεδομένα αυτής της μελέτης και συζητήστε στην εταιρεία σας πώς μπορείτε να εξελιχθείτε για να ανταποκριθείτε στις απαιτήσεις αυτών των νέων συνθηκών για τις εταιρείες',
      'Αξιοποιήστε αυτή την Μελέτη-Αξιολόγηση και συζητήστε τη βαθμολογία της εταιρείας σας με τους ανθρώπους-κλειδιά της ομάδας σας, χαράζοντας τη σωστή στρατηγική B2B μάρκετινγκ  προς όφελος της εταιρείας σας. Συζητήστε σχετικά με αποτελεσματικές καμπάνιες μάρκετινγκ τόσο με στελέχη μάρκετινγκ, με εμπορικά στελέχη όσο και με πωλητές για να τους δείξετε την απόδοση της επένδυσης (ROI) και την ανάγκη να ευθυγραμμιστεί η εταιρεία και  την «αναγκαιότητα» ύπαρξης μάρκετινγκ στην εταιρεία.',
      'Σχεδιάστε μερικές ενέργειες μάρκετινγκ ή καμπάνιες που θα αποδείξουν την επιτυχία και θα είναι αποδοτικές (πχ. απόκτηση νέου πελάτη) σε σχέση με προκαθορισμένες μετρήσεις. Αυτά τα προγράμματα και οι ενέργειες πρέπει να είναι μέρος ενός μακροπρόθεσμου προγράμματος μάρκετινγκ. Παρακολουθήστε στενά και προσπαθήστε να διατηρήσετε την ικανότητα, τη γνώση και την ταχύτητα ανταπόκρισης της ομάδας μάρκετινγκ της επιχείρησής σας ώστε να προσαρμοστείτε στα αποτελέσματα και στις νέες δυνατότητες που δημιουργούνται ως αποτέλεσμα αυτών των καμπανιών.',
      'Το μάρκετινγκ θα πρέπει να θεωρείται ως ένας ουσιαστικός παράγοντας που συμβάλλει στην αύξηση των εσόδων της εταιρείας σας. Συνεχίστε να χτίζετε μια εταιρική κουλτούρα μάρκετινγκ και ψηφιακής ανάπτυξης, ενισχύοντας τη θέση σας, την τοποθέτησή σας και δείχνοντας στους πελάτες σας ότι είστε μια σύγχρονη εταιρεία.',
    ];
    this.rec3 = [
      'Συνεργαστείτε επειγόντως με την εταιρεία μάρκετινγκ που σας υποστηρίζει, για να καθορίσετε τα βασικά βήματα μάρκετινγκ και ενέργειες που πρέπει να γίνουν και που δημιουργούν σταθερούς πυλώνες για το μάρκετινγκ της εταιρείας σας.',
      'Βεβαιωθείτε ότι τα βασικά στοιχεία μάρκετινγκ είναι όλα συγχρονισμένα με τους εταιρικούς σας στόχους, τις επιχειρηματικές προτεραιότητες και τις ανάγκες σας και προχωρήστε στα επόμενα βήματα του σχεδιασμού σας εμπλέκοντας και τα τμήματα πωλήσεων και ανάπτυξης, αναπτύσσοντας τις επόμενες φάσεις του πλάνου μάρκετινγκ που έχετε.',
      'Προχωρήστε για να αξιοποιήσετε περισσότερο τα βασικά και στοιχειώδη στοιχεία μάρκετινγκ που πρέπει να διαθέτει μια εταιρεία. Επιταχύνετε επεκτείνοντας τις αναλύσεις σας με βάση τα ευρήματα και τις παραδοχές από αντίστοιχες έρευνες και μελέτες. Εργαστείτε σε συμφωνία με την ομάδα Πωλήσεών σας για να αναπτύξετε μια βαθιά κατανόηση της συμπεριφοράς των αγοραστών σας μέσω της αγοραστικής διαδρομής των πελατών σας, ώστε να μπορείτε να εξελίξετε καλύτερα την Πρόταση Αξίας προς τους πελάτες της εταιρείας σας, τις αξίες και τη τοποθέτηση (positioning) έναντι των ανταγωνιστών σας.',
      'Έχετε αναβαθμισμένο μάρκετινγκ, δημιουργείτε σχετικά πρωτότυπο περιεχόμενο και η εταιρεία σας διαθέτει μια σταθερή υποδομή μάρκετινγκ. Βελτιώστε αυτά τα ουσιαστικά οργανωτικά και στοιχεία υποδομής μετρώντας την εμπειρία των πελατών σας με έρευνες (προσθέτοντας νέες μετρήσεις και κριτήρια) και αναπτύξτε το δικό σας εντελώς αυθεντικό περιεχόμενο που θα προσθέσει αξία στους πελάτες σας. Εντοπίστε περαιτέρω προσπάθειες βελτιστοποίησης των εκστρατειών με βάση τα διδάγματα από προηγούμενες καμπάνιες και εφαρμόστε αυτά τα μαθήματα στον σχεδιασμό μελλοντικών εκστρατειών.',
    ];
    this.rec4 = [
      'Διερευνήστε και εντοπίστε τις συνθήκες που ευνοούν την εγκατάσταση μιας πλατφόρμας CRM στην εταιρεία σας. Για να έχετε καλύτερα αποτελέσματα ενώ το υιοθετείτε, προσπαθήστε να εμπλέξετε τις ομάδες Πωλήσεων και Μάρκετινγκ και αφήστε τους να συνεισφέρουν με ιδέες και τρόπους για τη σωστή χρήση του.',
      'Σχεδιάστε και καθορίστε διαδικασίες και ροές εργασίας και στα δύο τμήματα (Πωλήσεις και Μάρκετινγκ) και ενσωματώστε όλα τα άλλα εργαλεία, ακόμα και τις μη αυτόματες ή πολύ παλιές αλλά χρήσιμες διαδικασίες που χρησιμοποιείτε, με στόχο να μετατρέψετε τους υποψήφιους πελάτες σε ευκαιρίες πώλησης. Η αγοραστική διαδρομή των πελατών (customer journey) θα πρέπει να είναι ο κατευθυντήριος οδηγός τόσο για το τι χρειάζεται να κάνει το CRM σας για εσάς όσο και να ξεκινήσετε τη στρατηγική σας CRM. Για καλύτερα αποτελέσματα, ενώ υιοθετείτε την πλατφόρμα CRM, προσπαθήστε να εμπλέξετε τις ομάδες Πωλήσεων και Μάρκετινγκ της εταιρείας και αφήστε τους να συνεισφέρουν με ιδέες και τρόπους για τη σωστή χρήση του. ',
      'Η ευθυγράμμιση Πωλήσεων-Μάρκετινγκ και το CRM δεν είναι εργαλεία plug-and-play που απλά προσθέτετε στην επιχείρηση. Πρέπει να βρίσκονται στον πυρήνα της επιχείρησής σας. Το καλό επίπεδο ευθυγράμμισης των ομάδων Πωλήσεων-Μάρκετινγκ και η λεπτομερής καταγραφή των διαδικασιών στο CRM θέτουν τα θεμέλια για την επιτυχή λειτουργία CRM και την υιοθέτηση του εντός της εταιρείας, και είναι ο μόνος τρόπος επιτυχούς δημιουργίας υποψήφιων πελατών και ανάπτυξης. Καθορίστε τα όρια μιας αυτοματοποιημένης διαδικασίας για να ελέγξετε την εξέλιξη των υποψήφιων πελατών σας μέσω συγκεκριμένων οδηγιών, που μοιράζονται μεταξύ των ομάδων Πωλήσεων και Μάρκετινγκ.',
      'Οι ομάδες σας, των Πωλήσεων και του Μάρκετινγκ είναι πλήρως ευθυγραμμισμένες, η αξιολόγηση των υποψηφίων επαφών σε "αξιόλογες" η δρομολόγησή τους στις Πωλήσεις και η βαθμολόγηση (στην εξέλιξη προς sales lead) είναι πλήρως αυτοματοποιημένα, όπως και οι αναφορές αγωγών (pipeline reports) είναι αυτοματοποιημένες και ενημερώνονται πλήρως. Έτσι η εταιρεία σας κερδίζει υψηλότερη αξία παραγγελίας από υποψήφιους πελάτες που επιδέχονται "καλλιέργεια" (φροντίδα με επικοινωνία κλπ). Η υιοθέτηση και η καλή χρήση της CRM πλατφόρμας  θα αναπτύξουν ροές μάρκετινγκ που θα ενεργοποιούν αυτόματα υποψήφιους πελάτες, θα φροντίζουν πελάτες με υπενθυμίσεις, θα πουλούν συμπληρωματικά ή και  θα αποκτάτε νέους πελάτες, φέρνοντας ουσιαστικά καλύτερα επιχειρηματικά αποτελέσματα.',
    ];
    this.rec5 = [
      'Η συγκριτική αξιολόγηση, οι μετρήσεις και τα αναλυτικά στοιχεία είναι σημαντικά στην επιχείρηση. Καθορίστε τα σημεία αναφοράς βάσει των οποίων μπορείτε να μετρήσετε την απόδοση σας όπως επίσης και τις προϋποθέσεις - προαπαιτούμενα για να σχεδιάσετε μια στρατηγική και να λάβετε αποφάσεις. Χρησιμοποιήστε μετρήσεις και αναλυτικά στοιχεία για να αξιολογήσετε την απόδοση των πρωτοβουλιών σας, έτσι ώστε να μπορείτε να κάνετε συνεχείς βελτιώσεις. Σήμερα οι δυνατότητες των marketing τεχνολογιών θεωρούνται απαραίτητο εργαλείο για την επιχειρηματική δραστηριότητα και όλοι εξοικειωνόμαστε με αυτήν την προοπτική.',
      'Προσπαθήστε να υιοθετήσετε ένα "νόμισμα" μάρκετινγκ για τις καμπάνιες μάρκετινγκ. Καθορίστε ένα σύνολο μετρήσεων σε ένα πλαίσιο μάρκετινγκ που θα είναι το "νόμισμα" μετρήσεων της εταιρείας σας για την κατανόηση των αποτελεσμάτων σχεδιασμού, εκτέλεσης και απόδοσης για τις ψηφιακές σας δραστηριότητες. Καθορίστε μετρήσεις σε θέματα όπως τμηματοποίηση κοινού, ταξινόμηση χρηστών, ποσοστά μετατροπών, συγκριτική αποτελεσματικότητα δημιουργικών θεμάτων, ψηφιακή απόδοση επένδυσης (ROI), μέγεθος, σχήμα και ταχύτητα του μάρκετινγκ αγωγού (pipeline) σας κ.λπ. Είναι σημαντικό να "ενσωματωθείτε" στη νέα B2B πραγματικότητα.',
      "Οι Βασικοί Δείκτες Απόδοσης (KPI's) εμφανίζουν μετρήσιμες-τιμές που δείχνουν την πρόοδο των επιχειρηματικών στόχων της εταιρείας σας. Οι Αναλύσεις (Analytics) μπορούν να σας βοηθήσουν να αξιολογήσετε την απόδοση της εταιρείας σας για να διασφαλίσετε ότι αυξάνετε συνεχώς την αξία και προσδιορίζετε τις ευκαιρίες που υπάρχουν για βελτίωση της απόδοσης. Παρακολουθήστε την πρόοδό σας μέσω των μετρήσιμων στόχων σας και αξιοποιήστε τους για να κάνετε κρίσιμες διορθώσεις-βελτιώσεις και να λαμβάνετε έγκαιρες αποφάσεις σχετικά με τα μελλοντικά σας σχέδια. Έτσι η εταιρεία σας θα είναι έτοιμη να προχωρήσει, βάσει αποφάσεων που βασίζονται σε δεδομένα.",
      'Είστε ήδη μια εταιρεία που βασίζεται και δουλεύει με στοιχεία και δεδομένα. Συνεχίστε να βελτιώνετε και να αναζητάτε-εμβαθύνετε σε αυτό το πλεονέκτημά σας για να κατανοήσετε πλήρως τη συμπεριφορά των αγοραστών μέσω της αγοραστικής διαδρομής (customer journey) των πελατών, καθώς και για να διαμορφώσετε μια τρέχουσα εικόνα των τάσεων της αγοράς. Μελλοντικά θα είστε σε ευνοϊκή θέση έναντι των ανταγωνιστών σας σχετικά με την επιχειρηματική σας στρατηγική και τη διαδικασία λήψης αποφάσεων.',
    ];

    this.rec6 = [
      'Συνεργαστείτε με τους ανθρώπους-κλειδιά και τα τμήματα της επιχείρησής σας για να αναδιαμορφώσετε το δικό σας λειτουργικό πλάνο, τρόπους, δομή και τις τρέχουσες και μελλοντικές προτεραιότητες, πριν ξεκινήσετε τον προγραμματισμό.',
      'Βεβαιωθείτε ότι το πλάνο μάρκετινγκ, οι λειτουργίες μάρκετινγκ και οι δραστηριότητες μάρκετινγκ είναι όλα συγχρονισμένα με τους εταιρικούς σας στόχους, τις επιχειρηματικές προτεραιότητες και τις ανάγκες σας. Είναι καλό να εμπλέξετε τις ομάδες Πωλήσεων και Ανάπτυξης Νέων Λογαριασμών για ομαδική συνεργασία, μόχλευση και περαιτέρω υλοποίηση με βάση τους στόχους που τίθενται.',
      'Είστε στο δρόμο της επιτυχίας, απομένει η ενοποιημένη αντιμετώπιση - η ολοκλήρωση, πάντως η εταιρεία σας βρίσκεται σε διαδικασία ανάπτυξης. Καθορίστε μια μακροπρόθεσμη καμπάνια, συγχρονίζοντας τη στρατηγική του brand σας με προτρεπτικά μηνύματα και ισχυρή τοποθέτηση (positioning) στην αγορά. Εξετάστε τις ανερχόμενες τάσεις του κλάδου και τους τρόπους δημιουργίας εκστρατειών που ανταποκρίνονται σε αυτές τις αναμενόμενες νέες τάσεις- ευκαιρίες. Τώρα μπορείτε να αυξήσετε τον προϋπολογισμό ορισμένων εκστρατειών, να δοκιμάσετε την ανταπόκρισή τους και τα έσοδα ή τα αποτελέσματα δημιουργίας υποψήφιων πελατών. Μπορείτε επίσης να αυξήσετε τους προϋπολογισμούς μάρκετινγκ με επιπλέον συναδέλφους στην ομάδα ή να βρείτε μια εξειδικευμένη εταιρεία μάρκετιγκ που θα σας βοηθήσει.',
      'Βρίσκεστε σε μια υγιή θέση σε σχέση με τις λειτουργίες μάρκετινγκ και η εταιρεία σας έχει σταθερό επίπεδο προγραμματισμού λειτουργιών μάρκετινγκ εκστρατειών. Η εταιρεία σας βρίσκεται σε διαδικασία ανάπτυξης. Τώρα μπορείτε να αυξήσετε τον προϋπολογισμό ορισμένων εκστρατειών, να δοκιμάσετε την ανταπόκρισή τους και τα έσοδα ή τα αποτελέσματα δημιουργίας υποψηφίων πελατών. Μέσω μιας βαθύτερης κατανόησης της συμπεριφοράς των πελατών, η εταιρεία σας μπορεί να προχωρήσει προς ένα μοντέλο πρόβλεψης όπου οι ενέργειες των πελατών μπορούν να προβλεφθούν πολύ πριν πραγματοποιηθούν πραγματικά. Αυτό θα σας επιτρέψει να επικοινωνείτε με στοχευμένα μηνύματα σε ομάδες και υπο-ομάδες κοινού που αναμένεται να συμπεριφέρονται με προβλεπόμενο τρόπο, ενώ αναλύετε τη συμπεριφορά του κοινού συνολικά.',
    ];
  }
  // / constr

  returnScores() {
    const scores = [
      this.firstScore,
      this.secondScore,
      this.thirdScore,
      this.forthScore,
      this.fifthScore,
      this.sixthScore,
    ];
    return scores;
  }

  setFirstScore(score) {
    this.firstScore = score;
  }
  setSecondScore(score) {
    this.secondScore = score;
  }
  setThirdScore(score) {
    this.thirdScore = score;
  }
  setForthScore(score) {
    this.forthScore = score;
  }
  setFifthScore(score) {
    this.fifthScore = score;
  }
  setSixthScore(score) {
    this.sixthScore = score;
  }
}
