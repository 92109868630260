import { Component, OnInit, ViewChild } from '@angular/core';
import {
  ApexNonAxisChartSeries,
  ApexResponsive,
  ApexChart,
  ApexStroke,
  ApexFill,
  ChartComponent,
  ApexAxisChartSeries,
  ApexTitleSubtitle,
  ApexXAxis,
} from 'ng-apexcharts';
import { LocalService } from 'src/app/services/local.service';
import { LocalServiceGR } from 'src/app/services/localgr.service';
import { LocalServiceFR } from 'src/app/services/localfr.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmComponent } from 'src/app/modals/confirm/confirm.component';
import { FiredataService } from 'src/app/services/firedata.service';
import { SharedurlComponent } from 'src/app/modals/sharedurl/sharedurl.component';
import { LanguageService } from 'src/app/services/language.service';
import { Observable, Subscription } from 'rxjs';
import * as enData from '../../../assets/i18n/en.json';
import * as elData from '../../../assets/i18n/el.json';
import * as frData from '../../../assets/i18n/fr.json';
import { Router, ActivatedRoute } from '@angular/router';

export type ChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  responsive: ApexResponsive[];
  labels: any;
  stroke: ApexStroke;
  fill: ApexFill;
};

export type ChartOptionsRadar = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  title: ApexTitleSubtitle;
  xaxis: ApexXAxis;
};
@Component({
  selector: 'app-assessment',
  templateUrl: './assessment.component.html',
  styleUrls: ['./assessment.component.scss'],
})
export class AssessmentPDFComponent implements OnInit {
  @ViewChild('chart') chart: ChartComponent;
  @ViewChild('chartRadar') chartRadar: ChartComponent;
  public chartOptions: any;
  public ChartOptionsRadar: any;
  res: any;
  confirmmodalRef: any;
  linkmodalRef: any;
  converted = '';
  firstScore = 0;
  secondScore = 0;
  thirdScore = 0;
  forthScore = 0;
  fifthScore = 0;
  sixthScore = 0;
  totalScore = 0;
  stringScore = '';
  scoreMessage = '';
  scoreTitle = '';
  recommendation1 = '';
  recommendation2 = '';
  recommendation3 = '';
  recommendation4 = '';
  recommendation5 = '';
  recommendation6 = '';
  fbAnswers = [];
  linkdinAnswrs = [];
  f1 = [];
  f2 = [];
  f3 = [];
  f4 = [];
  f5 = [];
  s1 = [];
  s2 = [];
  s3 = [];
  s4 = [];
  s5 = [];
  t1 = [];
  t2 = [];
  t3 = [];
  t4 = [];
  t5 = [];
  fo1 = [];
  fo2 = [];
  fo3 = [];
  fo4 = [];
  fo5 = [];
  fi1 = [];
  fi2 = [];
  fi3 = [];
  fi4 = [];
  si1 = [];
  si2 = [];
  si3 = [];
  si4 = [];
  today = new Date();
  company = 'Enzyme';
  currentLanguage: string;
  subscription: any;
  datadef: any = enData;
  data: any;
  localData: any;
  backgroundImage: string;
  topfasa: string;
  public href: string = '';
  url: string = 'asdf';
  testpage = false;
  assessmentId: string = '';

  constructor(
    private localDataen: LocalService,
    private localDatagr: LocalServiceGR,
    private localDatafr: LocalServiceFR,
    public modalService: NgbModal,
    private fireServ: FiredataService,
    private languageService: LanguageService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.data = this.datadef.default;
    this.localData = this.localDataen;

    this.setTexts();
    this.polarChart();

    // this.radarChart();
  }

  setTexts() {
    if (this.currentLanguage === 'en') {
      this.datadef = enData;
      // this.localData = this.localDataen;
      this.backgroundImage =
        '../../../assets/print/downloaded_pdf_interface_EN_Page1.jpg';
      this.topfasa = '../../../assets/print/topfacaEN.jpg';
    }
    if (this.currentLanguage === 'el') {
      this.datadef = elData;
      // this.localData = this.localDatagr;
      this.backgroundImage =
        '../../../assets/print/downloaded_pdf_interface_GR_Page1.jpg';
      this.topfasa = '../../../assets/print/topfacaGR.jpg';
    }
    if (this.currentLanguage === 'fr') {
      this.datadef = frData;
      // this.localData = this.localDatafr;
      this.backgroundImage =
        '../../../assets/print/downloaded_pdf_interface_FR_Page1.jpg';
      this.topfasa = '../../../assets/print/topfacaFR.jpg';
    }

    this.company = this.localData.company;
    if (this.testpage) {
      this.testpage = true;
      this.firstScore = 55;
      this.secondScore = 55;
      this.thirdScore = 53.75;
      this.forthScore = 50;
      this.fifthScore = 50;
      this.sixthScore = 57.5;
      this.totalScore = 57.88888;
    } else {
      this.firstScore = this.localData.firstScore;
      this.secondScore = this.localData.secondScore;
      this.thirdScore = this.localData.thirdScore;
      this.forthScore = this.localData.forthScore;
      this.fifthScore = this.localData.fifthScore;
      this.sixthScore = this.localData.sixthScore;
    }

    this.totalScore =
      (this.firstScore +
        this.secondScore +
        this.thirdScore +
        this.forthScore +
        this.fifthScore +
        this.sixthScore) /
      6;
    this.data = this.datadef.default;
    if (this.totalScore >= 76 && this.totalScore <= 100) {
      this.stringScore = this.data.assessment.stringScore76;
      this.scoreTitle = this.data.assessment.scoreTitle76;
      this.scoreMessage = this.data.assessment.scoreMessage76;
    }
    if (this.totalScore >= 51 && this.totalScore <= 75) {
      this.stringScore = this.data.assessment.stringScore51;
      this.scoreTitle = this.data.assessment.scoreTitle51;
      this.scoreMessage = this.data.assessment.scoreMessage51;
    }
    if (this.totalScore >= 26 && this.totalScore <= 50) {
      this.stringScore = this.data.assessment.stringScore26;
      this.scoreTitle = this.data.assessment.scoreTitle26;
      this.scoreMessage = this.data.assessment.scoreMessage26;
    }
    if (this.totalScore >= 0 && this.totalScore <= 25) {
      this.stringScore = this.data.assessment.stringScore0;
      this.scoreTitle = this.data.assessment.scoreTitle0;
      this.scoreMessage = this.data.assessment.scoreMessage0;
    }

    this.recommendAll();
    this.getAnswers();

    this.polarChart();
  }
  setTexts2() {
    if (this.currentLanguage === 'en') {
      this.datadef = enData;
      this.localData = this.localDataen;
      this.backgroundImage =
        '../../../assets/print/downloaded_pdf_interface_EN_Page1.jpg';
      this.topfasa = '../../../assets/print/topfacaEN.jpg';
    }
    if (this.currentLanguage === 'el') {
      this.datadef = elData;
      this.localData = this.localDatagr;
      this.backgroundImage =
        '../../../assets/print/downloaded_pdf_interface_GR_Page1.jpg';
      this.topfasa = '../../../assets/print/topfacaGR.jpg';
    }
    if (this.currentLanguage === 'fr') {
      this.datadef = frData;
      this.localData = this.localDatafr;
      this.backgroundImage =
        '../../../assets/print/downloaded_pdf_interface_FR_Page1.jpg';
      this.topfasa = '../../../assets/print/topfacaFR.jpg';
    }

    this.company = this.res.company;
    if (this.testpage) {
      this.testpage = true;
      this.firstScore = 55;
      this.secondScore = 55;
      this.thirdScore = 53.75;
      this.forthScore = 50;
      this.fifthScore = 50;
      this.sixthScore = 57.5;
      this.totalScore = 57.88888;
    } else {
      this.firstScore = this.res.firstScore;
      this.secondScore = this.res.secondScore;
      this.thirdScore = this.res.thirdScore;
      this.forthScore = this.res.forthScore;
      this.fifthScore = this.res.fifthScore;
      this.sixthScore = this.res.sixthScore;
    }

    this.totalScore =
      (this.firstScore +
        this.secondScore +
        this.thirdScore +
        this.forthScore +
        this.fifthScore +
        this.sixthScore) /
      6;
    this.data = this.datadef.default;
    if (this.totalScore >= 76 && this.totalScore <= 100) {
      this.stringScore = this.data.assessment.stringScore76;
      this.scoreTitle = this.data.assessment.scoreTitle76;
      this.scoreMessage = this.data.assessment.scoreMessage76;
    }
    if (this.totalScore >= 51 && this.totalScore <= 75) {
      this.stringScore = this.data.assessment.stringScore51;
      this.scoreTitle = this.data.assessment.scoreTitle51;
      this.scoreMessage = this.data.assessment.scoreMessage51;
    }
    if (this.totalScore >= 26 && this.totalScore <= 50) {
      this.stringScore = this.data.assessment.stringScore26;
      this.scoreTitle = this.data.assessment.scoreTitle26;
      this.scoreMessage = this.data.assessment.scoreMessage26;
    }
    if (this.totalScore >= 0 && this.totalScore <= 25) {
      this.stringScore = this.data.assessment.stringScore0;
      this.scoreTitle = this.data.assessment.scoreTitle0;
      this.scoreMessage = this.data.assessment.scoreMessage0;
    }

    this.recommendAll();
    this.getAnswers();

    this.polarChart();
  }

  ngOnInit(): void {
    this.href = this.router.url;
    if (this.router.url === '/assessment/test') {
      console.log('it is test');
      this.testpage = true;
      setTimeout(() => {
        this.setTexts();
        this.polarChart();
      }, 1000);
    } else {
      this.route.paramMap.subscribe((params) => {
        this.assessmentId = params.get('id') || '';
        if (this.assessmentId) {
          this.getDoc(this.assessmentId);
        }
      });
    }

    this.subscription = this.languageService.currentLanguage$.subscribe(
      (language) => {
        this.currentLanguage = language;
        this.setTexts();
        this.polarChart();
        console.log(this.currentLanguage);
      }
    );
  }

  async getDoc(id) {
    try {
      let res = await this.fireServ.getSubscription(id); // Now awaits a Promise

      this.company = res.company;
      this.languageService.changeLanguage(res.lang);
      // this.currentLanguage = res.lang;
      this.res = res;
      // console.log(res);
      // console.log(this.localData);

      setTimeout(() => {
        this.setTexts2();
      }, 500);
    } catch (error) {
      console.error('Error in getDoc:', error);
      // Handle the error appropriately, e.g., display an error message
    }
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  getAnswers() {
    this.f1 = this.localData.f1;
    this.f2 = this.localData.f2;
    this.f3 = this.localData.f3;
    this.f4 = this.localData.f4;
    this.f5 = this.localData.f5;

    this.s1 = this.localData.s1;
    this.s2 = this.localData.s2;
    this.s3 = this.localData.s3;
    this.s4 = this.localData.s4;
    this.s5 = this.localData.s5;

    this.t1 = this.localData.t1;
    this.t2 = this.localData.t2;
    this.t3 = this.localData.t3;
    this.t4 = this.localData.t4;
    this.t5 = this.localData.t5;

    this.fo1 = this.localData.fo1;
    this.fo2 = this.localData.fo2;
    this.fo3 = this.localData.fo3;
    this.fo4 = this.localData.fo4;
    this.fo5 = this.localData.fo5;

    this.fi1 = this.localData.fi1;
    this.fi2 = this.localData.fi2;
    this.fi3 = this.localData.fi3;
    this.fi4 = this.localData.fi4;

    this.si1 = this.localData.si1;
    this.si2 = this.localData.si2;
    this.si3 = this.localData.si3;
    this.si4 = this.localData.si4;

    this.fbAnswers = this.localData.fbAnswers;
    this.linkdinAnswrs = this.localData.linkdinAnswrs;
  }
  getAnswers2() {
    this.f1 = this.res.f1;
    this.f2 = this.res.f2;
    this.f3 = this.res.f3;
    this.f4 = this.res.f4;
    this.f5 = this.res.f5;

    this.s1 = this.res.s1;
    this.s2 = this.res.s2;
    this.s3 = this.res.s3;
    this.s4 = this.res.s4;
    this.s5 = this.res.s5;

    this.t1 = this.res.t1;
    this.t2 = this.res.t2;
    this.t3 = this.res.t3;
    this.t4 = this.res.t4;
    this.t5 = this.res.t5;

    this.fo1 = this.res.fo1;
    this.fo2 = this.res.fo2;
    this.fo3 = this.res.fo3;
    this.fo4 = this.res.fo4;
    this.fo5 = this.res.fo5;

    this.fi1 = this.res.fi1;
    this.fi2 = this.res.fi2;
    this.fi3 = this.res.fi3;
    this.fi4 = this.res.fi4;

    this.si1 = this.res.si1;
    this.si2 = this.res.si2;
    this.si3 = this.res.si3;
    this.si4 = this.res.si4;

    this.fbAnswers = this.localData.fbAnswers;
    this.linkdinAnswrs = this.localData.linkdinAnswrs;
  }

  recommendAll() {
    this.recommend(this.firstScore, 1);
    this.recommend(this.secondScore, 2);
    this.recommend(this.thirdScore, 3);
    this.recommend(this.forthScore, 4);
    this.recommend(this.fifthScore, 5);
    this.recommend(this.sixthScore, 6);
  }

  recommend(score, i) {
    var rec = '';
    switch (i) {
      case 1:
        var recs = this.localData.rec1;
        break;
      case 2:
        var recs = this.localData.rec2;
        break;
      case 3:
        var recs = this.localData.rec3;
        break;
      case 4:
        var recs = this.localData.rec4;
        break;
      case 5:
        var recs = this.localData.rec5;
        break;
      case 6:
        var recs = this.localData.rec6;
        break;

      // default:
      //   break;
    }

    if (score < 26) {
      rec = recs[0];
    }
    if (score < 51 && score > 25) {
      rec = recs[1];
    }
    if (score < 76 && score > 50) {
      rec = recs[2];
    }
    if (score < 101 && score > 75) {
      rec = recs[3];
    }
    switch (i) {
      case 1:
        this.recommendation1 = rec;
        break;
      case 2:
        this.recommendation2 = rec;
        break;
      case 3:
        this.recommendation3 = rec;
        break;
      case 4:
        this.recommendation4 = rec;
        break;
      case 5:
        this.recommendation5 = rec;
        break;
      case 6:
        this.recommendation6 = rec;
        break;

      default:
        break;
    }
  }
  recommendAll2() {
    this.recommend2(this.firstScore, 1);
    this.recommend2(this.secondScore, 2);
    this.recommend2(this.thirdScore, 3);
    this.recommend2(this.forthScore, 4);
    this.recommend2(this.fifthScore, 5);
    this.recommend2(this.sixthScore, 6);
  }

  recommend2(score, i) {
    var rec = '';
    switch (i) {
      case 1:
        var recs = this.res.rec1;
        break;
      case 2:
        var recs = this.res.rec2;
        break;
      case 3:
        var recs = this.res.rec3;
        break;
      case 4:
        var recs = this.res.rec4;
        break;
      case 5:
        var recs = this.res.rec5;
        break;
      case 6:
        var recs = this.res.rec6;
        break;

      // default:
      //   break;
    }

    if (score < 26) {
      rec = recs[0];
    }
    if (score < 51 && score > 25) {
      rec = recs[1];
    }
    if (score < 76 && score > 50) {
      rec = recs[2];
    }
    if (score < 101 && score > 75) {
      rec = recs[3];
    }
    switch (i) {
      case 1:
        this.recommendation1 = rec;
        break;
      case 2:
        this.recommendation2 = rec;
        break;
      case 3:
        this.recommendation3 = rec;
        break;
      case 4:
        this.recommendation4 = rec;
        break;
      case 5:
        this.recommendation5 = rec;
        break;
      case 6:
        this.recommendation6 = rec;
        break;

      default:
        break;
    }
  }
  // / recommend

  polarChart() {
    this.chartOptions = {
      series: [
        this.firstScore,
        this.secondScore,
        this.thirdScore,
        this.forthScore,
        this.fifthScore,
        this.sixthScore,
      ],
      yaxis: {
        max: 100,
        axisTicks: {
          show: true,
          borderType: 'solid',
          color: '#78909C',
          width: 6,
          offsetX: 0,
          offsetY: 0,
        },
        labels: {
          show: true,
          align: 'right',
          minWidth: 0,
          maxWidth: 160,
          style: {
            colors: [],
            fontSize: '12px',
            fontFamily: 'Helvetica, Arial, sans-serif',
            fontWeight: 400,
            cssClass: 'apexcharts-yaxis-label',
          },
          offsetX: 0,
          offsetY: 0,
          rotate: 0,
        },
        axisBorder: {
          show: true,
          color: '#78909C',
          offsetX: 0,
          offsetY: 0,
        },
      },
      chart: {
        type: 'polarArea',
        height: 600,
        legend: {
          show: false,
        },
      },
      stroke: {
        colors: ['#fff'],
      },
      colors: [
        '#FF7200',
        '#FFA800',
        '#FF5400',
        '#9E9E9E',
        '#545454',
        '#0A0500',
      ],
      fill: {
        opacity: 1,
      },
      labels: [
        this.data.assessment.chartTitle1,
        this.data.assessment.chartTitle2,
        this.data.assessment.chartTitle3,
        this.data.assessment.chartTitle4,
        this.data.assessment.chartTitle5,
        this.data.assessment.chartTitle6,
      ],
      legend: {
        show: true,
        position: 'bottom',
      },

      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
    };
    // / polar
  }

  setCharts() {
    console.log('charts');
    this.polarChart();
    // this.radarChart();
    this.recommendAll();
  }

  printPage() {
    window.focus();

    if (window.print) {
      // Check if print() is available
      window.print();
    } else {
      setTimeout(() => {
        window.print();
      }, 1000); // Wait a bit longer
    }
  }
  sharePage() {
    console.log('ask for share');
    this.confirmmodalRef = this.modalService.open(ConfirmComponent);
    this.confirmmodalRef.componentInstance.name = 'World';
    this.confirmmodalRef.result.then(
      (result) => {
        if (result) {
          console.log('share');
          const id = this.convertMobile('6937304123');

          const shareData = {
            firstScore: this.firstScore,
            secondScore: this.secondScore,
            thirdScore: this.thirdScore,
            forthScore: this.forthScore,
            fifthScore: this.fifthScore,
            sixthScore: this.sixthScore,
            totalScore: this.totalScore,
            id: id,
          };
          this.fireServ.createLink(shareData, id);
          this.linkmodalRef = this.modalService.open(SharedurlComponent);
          this.linkmodalRef.componentInstance.url = '/shared?id=' + id;
        }
      },
      (reason) => {
        console.log(reason);
      }
    );
  }

  convertMobile(mobile: string) {
    // 👇️ for Uppercase letters, replace `a` with `A`
    this.converted = '';
    for (let i = 0; i < mobile.length; i++) {
      const element = mobile[i];
      const code = 'a'.charCodeAt(0);
      console.log(code); // 👉️ 97
      this.converted += String.fromCharCode(code + parseInt(element));
    }

    return this.converted;
  }
}
