import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class FiredataService {
  submissions: Observable<any[]>;
  backendSubmissions: Observable<any[]>;
  sharelinks: Observable<any[]>;
  qDate = moment().subtract(30, 'days').format('DD/M/YYYY, hh:mm:ss μ.μ.');

  private subsCollection: AngularFirestoreCollection<any>;
  private backendSubsCollection: AngularFirestoreCollection<any>;
  private linksCollection: AngularFirestoreCollection<any>;

  constructor(private afs: AngularFirestore) {
    this.subsCollection = this.afs.collection<any>('submissions');
    this.backendSubsCollection = this.afs.collection<any>(
      'submissions',
      (ref) => ref.orderBy('date', 'desc')
    );
    this.linksCollection = this.afs.collection<any>('links');

    this.backendSubmissions = this.backendSubsCollection.valueChanges();
    this.submissions = this.subsCollection.valueChanges();
    this.sharelinks = this.linksCollection.valueChanges();
  } // /costr

  addSubscription(item: any) {
    this.subsCollection.add(item);
  }
  // / addSubscription

  setSubscription(item: any, id: string) {
    this.subsCollection.doc(id).set(item);
  }
  // / setSubscription
  getSubscription(id: string): Promise<any> {
    // Explicitly define return type
    return this.subsCollection // Return the promise
      .doc(id)
      .get()
      .toPromise()
      .then((doc) => {
        if (doc.exists) {
          return doc.data();
        } else {
          console.log('No such document!');
          return null; // Or throw an error
        }
      })
      .catch((error) => {
        console.error('Error getting document:', error);
        throw error; // Re-throw the error to be handled by the caller
      });
  }

  // / setSubscription

  createLink(data: any, id: string) {
    console.log(data);
    const col = this.afs.collection<any>('links', (ref) =>
      ref.where('id', '==', id)
    );
    const sub = col.valueChanges().subscribe((res) => {
      console.log(res);
      if (res.length > 0) {
        this.linksCollection.doc(id).update(data);
        console.log('updated');
      } else {
        this.linksCollection.doc(id).set(data);
        console.log('created');
      }
    });
    setTimeout(() => {
      sub.unsubscribe();
    }, 1000);
  }
  // / createLink

  getSubmissions() {
    return this.backendSubmissions;
  }

  setDate(q) {
    const tD = q.month + '/' + q.day + '/' + q.year;
    const nD = new Date(tD);

    this.qDate = moment(nD).format('DD/M/YYYY, hh:mm:ss μ.μ.');

    console.log(this.qDate);
    this.backendSubsCollection = this.afs.collection<any>(
      'submissions',
      (ref) => ref.where('datelocale', '<', this.qDate)
    );
    this.backendSubmissions = this.backendSubsCollection.valueChanges();
    return this.backendSubmissions;
  }
}
// / class
