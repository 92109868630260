<div class="queries">
  <!-- <ngb-datepicker (dateSelect)="onDateSelect($event)"></ngb-datepicker> -->
  <input
    #d="ngbDatepicker"
    type="text"
    ngbDatepicker
    (dateSelect)="onDateSelect($event)"
  />
  <button class="btn btn-primary" (click)="d.toggle()">Pick Date</button>
</div>
<div class="all">
  <div class="submission" *ngFor="let subItem of submissions | async">
    <ngb-accordion #acc="ngbAccordion">
      <ngb-panel>
        <ng-template ngbPanelTitle>
          <div class="headline">
            <div class="left">
              <span class="fullname">{{ subItem.fullname }}</span>
              <span class="date">{{
                subItem.date.seconds * 1000 | date : "medium"
              }}</span>
            </div>
            <div class="right">
              <span class="score"> Total Score: </span>
              <span class="totalscore">
                {{ subItem.totalScore.toFixed(2) }}
              </span>
            </div>
          </div>
        </ng-template>
        <ng-template ngbPanelContent>
          <div class="information">
            <div class="infoleft gridItem">
              <div class="infoItem">
                <div class="fieldInfo">Company:</div>
                <div class="fieldValue">{{ subItem.company }}</div>
              </div>
              <div class="infoItem">
                <div class="fieldInfo">Industry:</div>
                <div class="fieldValue">{{ subItem.industry }}</div>
              </div>
              <div class="infoItem">
                <div class="fieldInfo">Full Name:</div>
                <div class="fieldValue">{{ subItem.fullname }}</div>
              </div>
              <div class="infoItem">
                <div class="fieldInfo">Role:</div>
                <div class="fieldValue">{{ subItem.role }}</div>
              </div>
              <div class="infoItem">
                <div class="fieldInfo">Email:</div>
                <div class="fieldValue">{{ subItem.email }}</div>
              </div>
              <div class="infoItem">
                <div class="fieldInfo">Phone:</div>
                <div class="fieldValue">
                  <span>{{ subItem.phone }}</span>
                </div>
              </div>
            </div>
            <div class="center gridItem">
              <div class="infoItem">
                <div class="fieldInfo">How large:</div>
                <div class="fieldValue">{{ subItem.howLarge }}</div>
              </div>
              <div class="infoItem">
                <div class="fieldInfo">Average Sales:</div>
                <div class="fieldValue">{{ subItem.howLong }}</div>
              </div>
              <div class="infoItem">
                <div class="fieldInfo">Employees:</div>
                <div class="fieldValue">{{ subItem.howMany }} people</div>
              </div>
              <div class="infoItem">
                <div class="fieldInfo">Lifecycle Stage:</div>
                <div class="fieldValue">
                  {{ subItem.lifecycle | slice : 0 : 30 }}...
                </div>
              </div>
              <div class="infoItem">
                <div class="fieldInfo">Revenue Growth:</div>
                <div class="fieldValue">{{ subItem.growthGoal }}</div>
              </div>
              <div class="infoItem">
                <div class="fieldInfo">Marketing Budget:</div>
                <div class="fieldValue">{{ subItem.budget }}</div>
              </div>
            </div>
            <div class="right gridItem">
              <div class="infoItem">
                <div class="fieldInfo">Build Brand:</div>
                <div class="fieldValue">{{ subItem.brand }}</div>
              </div>
              <div class="infoItem">
                <div class="fieldInfo">Generate Leads:</div>
                <div class="fieldValue">{{ subItem.leads }}</div>
              </div>
              <div class="infoItem">
                <div class="fieldInfo">Increase Loyalty:</div>
                <div class="fieldValue">{{ subItem.loyalty }}</div>
              </div>
              <div class="infoItem">
                <div class="fieldInfo">Convert Sales:</div>
                <div class="fieldValue">
                  {{ subItem.sales }}
                </div>
              </div>
              <div class="infoItem">
                <div class="fieldInfo">Notes</div>
                <div class="fieldValue">{{ subItem.sxolia }}</div>
              </div>
              <div class="infoItem">
                <div class="fieldInfo">Link {{ subItem.urlid }}</div>
              </div>
            </div>
          </div>
          <ngb-accordion #secOne="ngbAccordion">
            <ngb-panel>
              <ng-template ngbPanelTitle>
                <div class="headline">
                  <span>Essential Marketing Infrastructure</span
                  ><span>{{ subItem.firstScore }}</span>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <div class="section">
                  <div class="question">1. {{ onequestions[0] }}</div>
                  <div class="answers">
                    <div class="answer" *ngFor="let ans of onefirstData">
                      {{ ans.name }}
                    </div>
                  </div>
                  <div class="replies">
                    <div
                      class="reply"
                      *ngFor="let rep of onefirstData; let i = index"
                    >
                      <div class="iftrue" *ngIf="subItem.replies.f1[i] != 'X'">
                        <div class="repscore">{{ rep.value }}</div>
                        <div class="check">&#10004;</div>
                      </div>
                      <div
                        class="iffalse"
                        *ngIf="subItem.replies.f1[i] === 'X'"
                      >
                        <div class="repscore">{{ rep.value }}</div>
                        <div class="problem">&#10006;</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="section">
                  <div class="question">2. {{ onequestions[1] }}</div>
                  <div class="answers">
                    <div class="answer" *ngFor="let ans of onesecondData">
                      {{ ans.name }}
                    </div>
                  </div>
                  <div class="replies">
                    <div
                      class="reply"
                      *ngFor="let rep of onesecondData; let i = index"
                    >
                      <div class="iftrue" *ngIf="subItem.replies.f2[i] != 'X'">
                        <div class="repscore">{{ rep.value }}</div>
                        <div class="check">&#10004;</div>
                      </div>
                      <div
                        class="iffalse"
                        *ngIf="subItem.replies.f2[i] === 'X'"
                      >
                        <div class="repscore">{{ rep.value }}</div>
                        <div class="problem">&#10006;</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="section">
                  <div class="question">3. {{ onequestions[2] }}</div>
                  <div class="answers">
                    <div class="answer" *ngFor="let ans of onethirdData">
                      {{ ans.name }}
                    </div>
                  </div>
                  <div class="replies">
                    <div
                      class="reply"
                      *ngFor="let rep of onethirdData; let i = index"
                    >
                      <div class="iftrue" *ngIf="subItem.replies.f3[i] != 'X'">
                        <div class="repscore">{{ rep.value }}</div>
                        <div class="check">&#10004;</div>
                      </div>
                      <div
                        class="iffalse"
                        *ngIf="subItem.replies.f3[i] === 'X'"
                      >
                        <div class="repscore">{{ rep.value }}</div>
                        <div class="problem">&#10006;</div>
                      </div>
                    </div>
                    <div class="more">
                      <div class="wrapsocial">
                        <span class="fb"> FB </span>
                        <div
                          class="fb"
                          *ngFor="let fb of subItem.replies.fbAnswers"
                        >
                          {{ fb }}
                        </div>
                      </div>
                      <div class="wrapsocial">
                        <div class="link">Linkedin</div>
                        <div
                          class="link"
                          *ngFor="let li of subItem.replies.linkdinAnswrs"
                        >
                          {{ li }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="section">
                  <div class="question">4. {{ onequestions[3] }}</div>
                  <div class="answers">
                    <div class="answer" *ngFor="let ans of oneforthData">
                      {{ ans.name }}
                    </div>
                  </div>
                  <div class="replies">
                    <div
                      class="reply"
                      *ngFor="let rep of oneforthData; let i = index"
                    >
                      <div class="iftrue" *ngIf="subItem.replies.f4[i] != 'X'">
                        <div class="repscore">
                          {{ rep.value }}
                        </div>
                        <div class="check">&#10004;</div>
                      </div>
                      <div
                        class="iffalse"
                        *ngIf="subItem.replies.f4[i] === 'X'"
                      >
                        <div class="repscore">{{ rep.value }}</div>
                        <div class="problem">&#10006;</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="section">
                  <div class="question">5. {{ onequestions[4] }}</div>
                  <div class="answers">
                    <div class="answer" *ngFor="let ans of onefifthData">
                      {{ ans.name }}
                    </div>
                  </div>
                  <div class="replies">
                    <div
                      class="reply"
                      *ngFor="let rep of onefifthData; let i = index"
                    >
                      <div class="iftrue" *ngIf="subItem.replies.f5[i] != 'X'">
                        <div class="repscore">
                          {{ rep.value }}
                        </div>
                        <div class="check">&#10004;</div>
                      </div>
                      <div
                        class="iffalse"
                        *ngIf="subItem.replies.f5[i] === 'X'"
                      >
                        <div class="repscore">{{ rep.value }}</div>
                        <div class="problem">&#10006;</div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </ngb-panel>
          </ngb-accordion>
          <ngb-accordion #secTwo="ngbAccordion">
            <ngb-panel>
              <ng-template ngbPanelTitle>
                <div class="headline">
                  <span>Organizational Area</span
                  ><span>{{ subItem.secondScore }}</span>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <div class="section">
                  <div class="question">1. {{ twoquestions[0] }}</div>
                  <div class="answers">
                    <div class="answer" *ngFor="let ans of twofirstData">
                      {{ ans.name }}
                    </div>
                  </div>
                  <div class="replies">
                    <div
                      class="reply"
                      *ngFor="let rep of twofirstData; let i = index"
                    >
                      <div class="iftrue" *ngIf="subItem.replies.s1[i] != 'X'">
                        <div class="repscore">{{ rep.value }}</div>
                        <div class="check">&#10004;</div>
                      </div>
                      <div
                        class="iffalse"
                        *ngIf="subItem.replies.s1[i] === 'X'"
                      >
                        <div class="repscore">{{ rep.value }}</div>
                        <div class="problem">&#10006;</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="section">
                  <div class="question">2. {{ twoquestions[1] }}</div>
                  <div class="answers">
                    <div class="answer" *ngFor="let ans of twosecondData">
                      {{ ans.name }}
                    </div>
                  </div>
                  <div class="replies">
                    <div
                      class="reply"
                      *ngFor="let rep of twosecondData; let i = index"
                    >
                      <div class="iftrue" *ngIf="subItem.replies.s2[i] != 'X'">
                        <div class="repscore">{{ rep.value }}</div>
                        <div class="check">&#10004;</div>
                      </div>
                      <div
                        class="iffalse"
                        *ngIf="subItem.replies.s2[i] === 'X'"
                      >
                        <div class="repscore">{{ rep.value }}</div>
                        <div class="problem">&#10006;</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="section">
                  <div class="question">3. {{ twoquestions[2] }}</div>
                  <div class="answers">
                    <div class="answer" *ngFor="let ans of twothirdData">
                      {{ ans.name }}
                    </div>
                  </div>
                  <div class="replies">
                    <div
                      class="reply"
                      *ngFor="let rep of twothirdData; let i = index"
                    >
                      <div class="iftrue" *ngIf="subItem.replies.s3[i] != 'X'">
                        <div class="repscore">{{ rep.value }}</div>
                        <div class="check">&#10004;</div>
                      </div>
                      <div
                        class="iffalse"
                        *ngIf="subItem.replies.s3[i] === 'X'"
                      >
                        <div class="repscore">{{ rep.value }}</div>
                        <div class="problem">&#10006;</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="section">
                  <div class="question">4. {{ twoquestions[3] }}</div>
                  <div class="answers">
                    <div class="answer" *ngFor="let ans of twoforthData">
                      {{ ans.name }}
                    </div>
                  </div>
                  <div class="replies">
                    <div
                      class="reply"
                      *ngFor="let rep of twoforthData; let i = index"
                    >
                      <div class="iftrue" *ngIf="subItem.replies.s4[i] != 'X'">
                        <div class="repscore">
                          {{ rep.value }}
                        </div>
                        <div class="check">&#10004;</div>
                      </div>
                      <div
                        class="iffalse"
                        *ngIf="subItem.replies.s4[i] === 'X'"
                      >
                        <div class="repscore">{{ rep.value }}</div>
                        <div class="problem">&#10006;</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="section">
                  <div class="question">5. {{ twoquestions[4] }}</div>
                  <div class="answers">
                    <div class="answer" *ngFor="let ans of twofifthData">
                      {{ ans.name }}
                    </div>
                  </div>
                  <div class="replies">
                    <div
                      class="reply"
                      *ngFor="let rep of twofifthData; let i = index"
                    >
                      <div class="iftrue" *ngIf="subItem.replies.s5[i] != 'X'">
                        <div class="repscore">
                          {{ rep.value }}
                        </div>
                        <div class="check">&#10004;</div>
                      </div>
                      <div
                        class="iffalse"
                        *ngIf="subItem.replies.s5[i] === 'X'"
                      >
                        <div class="repscore">{{ rep.value }}</div>
                        <div class="problem">&#10006;</div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </ngb-panel>
          </ngb-accordion>
          <ngb-accordion #secTwo="ngbAccordion">
            <ngb-panel>
              <ng-template ngbPanelTitle>
                <div class="headline">
                  <span>Strategy Area</span
                  ><span>{{ subItem.thirdScore }}</span>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <div class="section">
                  <div class="question">1. {{ threequestions[0] }}</div>
                  <div class="answers">
                    <div class="answer" *ngFor="let ans of threefirstData">
                      {{ ans.name }}
                    </div>
                  </div>
                  <div class="replies">
                    <div
                      class="reply"
                      *ngFor="let rep of threefirstData; let i = index"
                    >
                      <div class="iftrue">
                        <div class="repscore">{{ rep.value }}</div>
                        <div class="check">
                          {{ (subItem.replies.t1[i] + 1) * 5 }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="section">
                  <div class="question">2. {{ threequestions[1] }}</div>
                  <div class="answers">
                    <div class="answer" *ngFor="let ans of threesecondData">
                      {{ ans.name }}
                    </div>
                  </div>
                  <div class="replies">
                    <div
                      class="reply"
                      *ngFor="let rep of threesecondData; let i = index"
                    >
                      <div class="iftrue">
                        <div class="repscore">{{ rep.value }}</div>
                        <div class="check">
                          {{ (subItem.replies.t2[i] + 1) * 5 }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="section">
                  <div class="question">3. {{ threequestions[2] }}</div>
                  <div class="answers">
                    <div class="answer" *ngFor="let ans of threethirdData">
                      {{ ans.name }}
                    </div>
                  </div>
                  <div class="replies">
                    <div
                      class="reply"
                      *ngFor="let rep of threethirdData; let i = index"
                    >
                      <div class="iftrue" *ngIf="subItem.replies.t3[i] != 'X'">
                        <div class="repscore">{{ rep.value }}</div>
                        <div class="check">&#10004;</div>
                      </div>
                      <div
                        class="iffalse"
                        *ngIf="subItem.replies.t3[i] === 'X'"
                      >
                        <div class="repscore">{{ rep.value }}</div>
                        <div class="problem">&#10006;</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="section">
                  <div class="question">4. {{ threequestions[3] }}</div>
                  <div class="answers">
                    <div class="answer" *ngFor="let ans of threeforthData">
                      {{ ans.name }}
                    </div>
                  </div>
                  <div class="replies">
                    <div
                      class="reply"
                      *ngFor="let rep of threeforthData; let i = index"
                    >
                      <div class="iftrue" *ngIf="subItem.replies.t4[i] != 'X'">
                        <div class="repscore">
                          {{ rep.value }}
                        </div>
                        <div class="check">&#10004;</div>
                      </div>
                      <div
                        class="iffalse"
                        *ngIf="subItem.replies.t4[i] === 'X'"
                      >
                        <div class="repscore">{{ rep.value }}</div>
                        <div class="problem">&#10006;</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="section">
                  <div class="question">5. {{ threequestions[4] }}</div>
                  <div class="answers">
                    <div class="answer" *ngFor="let ans of threefifthData">
                      {{ ans.name }}
                    </div>
                  </div>
                  <div class="replies">
                    <div
                      class="reply"
                      *ngFor="let rep of threefifthData; let i = index"
                    >
                      <div class="iftrue">
                        <div class="repscore">{{ rep.value }}</div>
                        <div class="check">
                          {{ (subItem.replies.t5[i] + 1) * 5 }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </ngb-panel>
          </ngb-accordion>
          <ngb-accordion #secTwo="ngbAccordion">
            <ngb-panel>
              <ng-template ngbPanelTitle>
                <div class="headline">
                  <span>CRM /ABM Area</span
                  ><span>{{ subItem.forthScore }}</span>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <div class="section">
                  <div class="question">1. {{ forthquestions[0] }}</div>
                  <div class="answers">
                    <div class="answer" *ngFor="let ans of forthfirstData">
                      {{ ans.name }}
                    </div>
                  </div>
                  <div class="replies">
                    <div
                      class="reply"
                      *ngFor="let rep of forthfirstData; let i = index"
                    >
                      <div class="iftrue" *ngIf="subItem.replies.fo1[i] != 'X'">
                        <div class="repscore">{{ rep.value }}</div>
                        <div class="check">&#10004;</div>
                      </div>
                      <div
                        class="iffalse"
                        *ngIf="subItem.replies.fo1[i] === 'X'"
                      >
                        <div class="repscore">{{ rep.value }}</div>
                        <div class="problem">&#10006;</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="section">
                  <div class="question">2. {{ forthquestions[1] }}</div>
                  <div class="answers">
                    <div class="answer" *ngFor="let ans of forthsecondData">
                      {{ ans.name }}
                    </div>
                  </div>
                  <div class="replies">
                    <div
                      class="reply"
                      *ngFor="let rep of forthsecondData; let i = index"
                    >
                      <div class="iftrue" *ngIf="subItem.replies.fo2[i] != 'X'">
                        <div class="repscore">{{ rep.value }}</div>
                        <div class="check">&#10004;</div>
                      </div>
                      <div
                        class="iffalse"
                        *ngIf="subItem.replies.fo2[i] === 'X'"
                      >
                        <div class="repscore">{{ rep.value }}</div>
                        <div class="problem">&#10006;</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="section">
                  <div class="question">3. {{ forthquestions[2] }}</div>
                  <div class="answers">
                    <div class="answer" *ngFor="let ans of forththirdData">
                      {{ ans.name }}
                    </div>
                  </div>
                  <div class="replies">
                    <div
                      class="reply"
                      *ngFor="let rep of forththirdData; let i = index"
                    >
                      <div class="iftrue" *ngIf="subItem.replies.fo3[i] != 'X'">
                        <div class="repscore">{{ rep.value }}</div>
                        <div class="check">&#10004;</div>
                      </div>
                      <div
                        class="iffalse"
                        *ngIf="subItem.replies.fo3[i] === 'X'"
                      >
                        <div class="repscore">{{ rep.value }}</div>
                        <div class="problem">&#10006;</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="section">
                  <div class="question">4. {{ forthquestions[3] }}</div>
                  <div class="answers">
                    <div class="answer" *ngFor="let ans of forthforthData">
                      {{ ans.name }}
                    </div>
                  </div>
                  <div class="replies">
                    <div
                      class="reply"
                      *ngFor="let rep of forthforthData; let i = index"
                    >
                      <div class="iftrue" *ngIf="subItem.replies.fo4[i] != 'X'">
                        <div class="repscore">
                          {{ rep.value }}
                        </div>
                        <div class="check">&#10004;</div>
                      </div>
                      <div
                        class="iffalse"
                        *ngIf="subItem.replies.fo4[i] === 'X'"
                      >
                        <div class="repscore">{{ rep.value }}</div>
                        <div class="problem">&#10006;</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="section">
                  <div class="question">5. {{ forthquestions[4] }}</div>
                  <div class="answers">
                    <div class="answer" *ngFor="let ans of forthfifthData">
                      {{ ans.name }}
                    </div>
                  </div>
                  <div class="replies">
                    <div
                      class="reply"
                      *ngFor="let rep of forthfifthData; let i = index"
                    >
                      <div class="iftrue" *ngIf="subItem.replies.fo5[i] != 'X'">
                        <div class="repscore">
                          {{ rep.value }}
                        </div>
                        <div class="check">&#10004;</div>
                      </div>
                      <div
                        class="iffalse"
                        *ngIf="subItem.replies.fo5[i] === 'X'"
                      >
                        <div class="repscore">{{ rep.value }}</div>
                        <div class="problem">&#10006;</div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </ngb-panel>
          </ngb-accordion>
          <ngb-accordion #secTwo="ngbAccordion">
            <ngb-panel>
              <ng-template ngbPanelTitle>
                <div class="headline">
                  <span>Performance Area</span
                  ><span>{{ subItem.fifthScore }}</span>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <div class="section">
                  <div class="question">1. {{ fifthquestions[0] }}</div>
                  <div class="answers">
                    <div class="answer" *ngFor="let ans of fifthfirstData">
                      {{ ans.name }}
                    </div>
                  </div>
                  <div class="replies">
                    <div
                      class="reply"
                      *ngFor="let rep of fifthfirstData; let i = index"
                    >
                      <div class="iftrue" *ngIf="subItem.replies.fi1[i] != 'X'">
                        <div class="repscore">{{ rep.value }}</div>
                        <div class="check">&#10004;</div>
                      </div>
                      <div
                        class="iffalse"
                        *ngIf="subItem.replies.fi1[i] === 'X'"
                      >
                        <div class="repscore">{{ rep.value }}</div>
                        <div class="problem">&#10006;</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="section">
                  <div class="question">2. {{ fifthquestions[1] }}</div>
                  <div class="answers">
                    <div class="answer" *ngFor="let ans of fifthsecondData">
                      {{ ans.name }}
                    </div>
                  </div>
                  <div class="replies">
                    <div
                      class="reply"
                      *ngFor="let rep of fifthsecondData; let i = index"
                    >
                      <div class="iftrue" *ngIf="subItem.replies.fi2[i] != 'X'">
                        <div class="repscore">{{ rep.value }}</div>
                        <div class="check">&#10004;</div>
                      </div>
                      <div
                        class="iffalse"
                        *ngIf="subItem.replies.fi2[i] === 'X'"
                      >
                        <div class="repscore">{{ rep.value }}</div>
                        <div class="problem">&#10006;</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="section">
                  <div class="question">3. {{ fifthquestions[2] }}</div>
                  <div class="answers">
                    <div class="answer" *ngFor="let ans of fifththirdData">
                      {{ ans.name }}
                    </div>
                  </div>
                  <div class="replies">
                    <div
                      class="reply"
                      *ngFor="let rep of fifththirdData; let i = index"
                    >
                      <div class="iftrue" *ngIf="subItem.replies.fi3[i] != 'X'">
                        <div class="repscore">{{ rep.value }}</div>
                        <div class="check">&#10004;</div>
                      </div>
                      <div
                        class="iffalse"
                        *ngIf="subItem.replies.fi3[i] === 'X'"
                      >
                        <div class="repscore">{{ rep.value }}</div>
                        <div class="problem">&#10006;</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="section">
                  <div class="question">4. {{ fifthquestions[3] }}</div>
                  <div class="answers">
                    <div class="answer" *ngFor="let ans of fifthforthData">
                      {{ ans.name }}
                    </div>
                  </div>
                  <div class="replies">
                    <div
                      class="reply"
                      *ngFor="let rep of fifthforthData; let i = index"
                    >
                      <div class="iftrue" *ngIf="subItem.replies.fi4[i] != 'X'">
                        <div class="repscore">
                          {{ rep.value }}
                        </div>
                        <div class="check">&#10004;</div>
                      </div>
                      <div
                        class="iffalse"
                        *ngIf="subItem.replies.fi4[i] === 'X'"
                      >
                        <div class="repscore">{{ rep.value }}</div>
                        <div class="problem">&#10006;</div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </ngb-panel>
          </ngb-accordion>
          <ngb-accordion #secTwo="ngbAccordion">
            <ngb-panel>
              <ng-template ngbPanelTitle>
                <div class="headline">
                  <span>Operational Area</span
                  ><span>{{ subItem.sixthScore }}</span>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <div class="section">
                  <div class="question">1. {{ sixthquestions[0] }}</div>
                  <div class="answers">
                    <div class="answer" *ngFor="let ans of sixthfirstData">
                      {{ ans.name }}
                    </div>
                  </div>
                  <div class="replies">
                    <div
                      class="reply"
                      *ngFor="let rep of sixthfirstData; let i = index"
                    >
                      <div class="iftrue" *ngIf="subItem.replies.si1[i] != 'X'">
                        <div class="repscore">{{ rep.value }}</div>
                        <div class="check">&#10004;</div>
                      </div>
                      <div
                        class="iffalse"
                        *ngIf="subItem.replies.si1[i] === 'X'"
                      >
                        <div class="repscore">{{ rep.value }}</div>
                        <div class="problem">&#10006;</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="section">
                  <div class="question">2. {{ sixthquestions[1] }}</div>
                  <div class="answers">
                    <div class="answer" *ngFor="let ans of sixthsecondData">
                      {{ ans.name }}
                    </div>
                  </div>
                  <div class="replies">
                    <div
                      class="reply"
                      *ngFor="let rep of sixthsecondData; let i = index"
                    >
                      <div class="iftrue" *ngIf="subItem.replies.si2[i] != 'X'">
                        <div class="repscore">{{ rep.value }}</div>
                        <div class="check">&#10004;</div>
                      </div>
                      <div
                        class="iffalse"
                        *ngIf="subItem.replies.si2[i] === 'X'"
                      >
                        <div class="repscore">{{ rep.value }}</div>
                        <div class="problem">&#10006;</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="section">
                  <div class="question">3. {{ sixthquestions[2] }}</div>
                  <div class="answers">
                    <div class="answer" *ngFor="let ans of sixththirdData">
                      {{ ans.name }}
                    </div>
                  </div>
                  <div class="replies">
                    <div
                      class="reply"
                      *ngFor="let rep of sixththirdData; let i = index"
                    >
                      <div class="iftrue" *ngIf="subItem.replies.si3[i] != 'X'">
                        <div class="repscore">{{ rep.value }}</div>
                        <div class="check">&#10004;</div>
                      </div>
                      <div
                        class="iffalse"
                        *ngIf="subItem.replies.si3[i] === 'X'"
                      >
                        <div class="repscore">{{ rep.value }}</div>
                        <div class="problem">&#10006;</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="section">
                  <div class="question">4. {{ sixthquestions[3] }}</div>
                  <div class="answers">
                    <div class="answer" *ngFor="let ans of sixthforthData">
                      {{ ans.name }}
                    </div>
                  </div>
                  <div class="replies">
                    <div
                      class="reply"
                      *ngFor="let rep of sixthforthData; let i = index"
                    >
                      <div class="iftrue" *ngIf="subItem.replies.si4[i] != 'X'">
                        <div class="repscore">
                          {{ rep.value }}
                        </div>
                        <div class="check">&#10004;</div>
                      </div>
                      <div
                        class="iffalse"
                        *ngIf="subItem.replies.si4[i] === 'X'"
                      >
                        <div class="repscore">{{ rep.value }}</div>
                        <div class="problem">&#10006;</div>
                      </div>
                    </div>
                  </div>
                </div></ng-template
              >
            </ngb-panel>
          </ngb-accordion>
        </ng-template>
      </ngb-panel>
    </ngb-accordion>
  </div>
</div>

<!-- <div class="replies">
  <div class="answers">
    <div class="qreplies">
      1a -
      <div class="repl" *ngFor="let item of subItem.replies.f1">
        <span class="badge badge-secondary">{{ item + 1 }}</span>
      </div>
    </div>
    <div class="qreplies">
      1b -
      <div class="repl" *ngFor="let item of subItem.replies.f2">
        <span class="badge badge-secondary">{{ item + 1 }}</span>
      </div>
    </div>
    <div class="qreplies">
      1c -
      <div class="repl" *ngFor="let item of subItem.replies.f3">
        <span class="badge badge-secondary">{{ item }}</span>
        <div class="fb" *ngFor="let y of subItem.replies.fbAnswers">
          <span class="badge badge-warning">{{ y }}</span>
        </div>
        <div
          class="linkd"
          *ngFor="let z of subItem.replies.linkdinAnswrs"
        >
          <span class="badge badge-warning">{{ z }}</span>
        </div>
      </div>
    </div>
    <div class="qreplies">
      1d -
      <div class="repl" *ngFor="let item of subItem.replies.f4">
        <span class="badge badge-secondary">{{ item + 1 }}</span>
      </div>
    </div>
    <div class="qreplies">
      1e -
      <div class="repl" *ngFor="let item of subItem.replies.f5">
        <span class="badge badge-secondary">{{ item + 1 }}</span>
      </div>
    </div>
    <div class="qreplies">
      2a -
      <div class="repl" *ngFor="let item of subItem.replies.s1">
        <span class="badge badge-secondary">{{ item + 1 }}</span>
      </div>
    </div>
    <div class="qreplies">
      2b -
      <div class="repl" *ngFor="let item of subItem.replies.s2">
        <span class="badge badge-secondary">{{ item + 1 }}</span>
      </div>
    </div>
    <div class="qreplies">
      2c -
      <div class="repl" *ngFor="let item of subItem.replies.s3">
        <span class="badge badge-secondary">{{ item + 1 }}</span>
      </div>
    </div>
    <div class="qreplies">
      2d -
      <div class="repl" *ngFor="let item of subItem.replies.s4">
        <span class="badge badge-secondary">{{ item + 1 }}</span>
      </div>
    </div>
    <div class="qreplies">
      2e -
      <div class="repl" *ngFor="let item of subItem.replies.s5">
        <span class="badge badge-secondary">{{ item + 1 }}</span>
      </div>
    </div>
    <div class="qreplies">
      3a -
      <div class="repl" *ngFor="let item of subItem.replies.t1">
        <span class="badge badge-secondary">{{ item + 1 }}</span>
      </div>
    </div>
    <div class="qreplies">
      3b -
      <div class="repl" *ngFor="let item of subItem.replies.t2">
        <span class="badge badge-secondary">{{ item + 1 }}</span>
      </div>
    </div>
    <div class="qreplies">
      3c -
      <div class="repl" *ngFor="let item of subItem.replies.t3">
        <span class="badge badge-secondary">{{ item + 1 }}</span>
      </div>
    </div>
    <div class="qreplies">
      3d -
      <div class="repl" *ngFor="let item of subItem.replies.t4">
        <span class="badge badge-secondary">{{ item + 1 }}</span>
      </div>
    </div>
    <div class="qreplies">
      3e -
      <div class="repl" *ngFor="let item of subItem.replies.t5">
        <span class="badge badge-secondary">{{ item + 1 }}</span>
      </div>
    </div>
    <div class="qreplies">
      4a -
      <div class="repl" *ngFor="let item of subItem.replies.fo1">
        <span class="badge badge-secondary">{{ item + 1 }}</span>
      </div>
    </div>
    <div class="qreplies">
      4b -
      <div class="repl" *ngFor="let item of subItem.replies.fo2">
        <span class="badge badge-secondary">{{ item + 1 }}</span>
      </div>
    </div>
    <div class="qreplies">
      4c -
      <div class="repl" *ngFor="let item of subItem.replies.fo3">
        <span class="badge badge-secondary">{{ item + 1 }}</span>
      </div>
    </div>
    <div class="qreplies">
      4d -
      <div class="repl" *ngFor="let item of subItem.replies.fo4">
        <span class="badge badge-secondary">{{ item + 1 }}</span>
      </div>
    </div>
    <div class="qreplies">
      4e -
      <div class="repl" *ngFor="let item of subItem.replies.fo5">
        <span class="badge badge-secondary">{{ item + 1 }}</span>
      </div>
    </div>
    <div class="qreplies">
      5a -
      <div class="repl" *ngFor="let item of subItem.replies.fi1">
        <span class="badge badge-secondary">{{ item + 1 }}</span>
      </div>
    </div>
    <div class="qreplies">
      5b -
      <div class="repl" *ngFor="let item of subItem.replies.fi2">
        <span class="badge badge-secondary">{{ item + 1 }}</span>
      </div>
    </div>
    <div class="qreplies">
      5c -
      <div class="repl" *ngFor="let item of subItem.replies.fi3">
        <span class="badge badge-secondary">{{ item + 1 }}</span>
      </div>
    </div>
    <div class="qreplies">
      5d -
      <div class="repl" *ngFor="let item of subItem.replies.fi4">
        <span class="badge badge-secondary">{{ item + 1 }}</span>
      </div>
    </div>
    <div class="qreplies">
      6a -
      <div class="repl" *ngFor="let item of subItem.replies.si1">
        <span class="badge badge-secondary">{{ item + 1 }}</span>
      </div>
    </div>
    <div class="qreplies">
      6b -
      <div class="repl" *ngFor="let item of subItem.replies.si2">
        <span class="badge badge-secondary">{{ item + 1 }}</span>
      </div>
    </div>
    <div class="qreplies">
      6c -
      <div class="repl" *ngFor="let item of subItem.replies.si3">
        <span class="badge badge-secondary">{{ item + 1 }}</span>
      </div>
    </div>
    <div class="qreplies">
      6d -
      <div class="repl" *ngFor="let item of subItem.replies.si4">
        <span class="badge badge-secondary">{{ item + 1 }}</span>
      </div>
    </div>
  </div>
</div> -->
