import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AssessmentComponent } from './pages/assessment/assessment.component';
import { BackendComponent } from './pages/backend/backend.component';
import { FifthComponent } from './pages/fifth/fifth.component';
import { FirstComponent } from './pages/first/first.component';
import { ForthComponent } from './pages/forth/forth.component';
import { GetstartedComponent } from './pages/getstarted/getstarted.component';
import { HomeComponent } from './pages/home/home.component';
import { HowitworksComponent } from './pages/howitworks/howitworks.component';
import { LoginComponent } from './pages/login/login.component';
import { LearnmoreComponent } from './pages/learnmore/learnmore.component';
import { RegistrationComponent } from './pages/registration/registration.component';
import { SecondComponent } from './pages/second/second.component';
import { SixthComponent } from './pages/sixth/sixth.component';
import { ThirdComponent } from './pages/third/third.component';
import { SharedComponent } from './pages/assessment/shared/shared.component';
import { AssessmentPDFComponent } from './pages/assessmentpdf/assessment.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'home',
    component: HomeComponent,
  },
  {
    path: 'learnmore',
    component: LearnmoreComponent,
  },
  {
    path: 'getstarted',
    component: GetstartedComponent,
  },
  {
    path: 'howitworks',
    component: HowitworksComponent,
  },
  {
    path: 'first',
    component: FirstComponent,
  },
  {
    path: 'second',
    component: SecondComponent,
  },
  {
    path: 'third',
    component: ThirdComponent,
  },
  {
    path: 'forth',
    component: ForthComponent,
  },
  {
    path: 'fifth',
    component: FifthComponent,
  },
  {
    path: 'sixth',
    component: SixthComponent,
  },
  {
    path: 'registration',
    component: RegistrationComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'backend',
    component: BackendComponent,
  },
  {
    path: 'assessment/:id',
    component: AssessmentComponent,
  },
  {
    path: 'assessmentpdf/:id',
    component: AssessmentPDFComponent,
  },
  {
    path: 'shared',
    component: SharedComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
export const routingComponents = [
  HomeComponent,
  FirstComponent,
  SecondComponent,
  ThirdComponent,
  ForthComponent,
  FifthComponent,
  SixthComponent,
  RegistrationComponent,
  AssessmentComponent,
  AssessmentPDFComponent,
  BackendComponent,
  LoginComponent,
  GetstartedComponent,
  HowitworksComponent,
  LearnmoreComponent,
  SharedComponent,
];
