import { Component, OnInit } from '@angular/core';
import { FiredataService } from 'src/app/services/firedata.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { LocalService } from 'src/app/services/local.service';

@Component({
  selector: 'app-backend',
  templateUrl: './backend.component.html',
  styleUrls: ['./backend.component.scss'],
})
export class BackendComponent implements OnInit {
  submissions: any;
  onefirstData: Array<any> = [];
  onesecondData: Array<any> = [];
  onethirdData: Array<any> = [];
  oneforthData: Array<any> = [];
  onefifthData: Array<any> = [];
  onequestions: any = [];
  twofirstData: Array<any> = [];
  twosecondData: Array<any> = [];
  twothirdData: Array<any> = [];
  twoforthData: Array<any> = [];
  twofifthData: Array<any> = [];
  twoquestions: any = [];

  threefirstData: Array<any> = [];
  threesecondData: Array<any> = [];
  threethirdData: Array<any> = [];
  threeforthData: Array<any> = [];
  threefifthData: Array<any> = [];
  threequestions: any = [];

  forthfirstData: Array<any> = [];
  forthsecondData: Array<any> = [];
  forththirdData: Array<any> = [];
  forthforthData: Array<any> = [];
  forthfifthData: Array<any> = [];
  forthquestions: any = [];

  fifthfirstData: Array<any> = [];
  fifthsecondData: Array<any> = [];
  fifththirdData: Array<any> = [];
  fifthforthData: Array<any> = [];
  fifthquestions: any = [];

  sixthfirstData: Array<any> = [];
  sixthsecondData: Array<any> = [];
  sixththirdData: Array<any> = [];
  sixthforthData: Array<any> = [];
  sixthquestions: any = [];

  private unSub: Subscription;
  constructor(
    private fireServ: FiredataService,
    private router: Router,
    private localData: LocalService
  ) {
    this.onefirstData = this.localData.firstSection[0].questions;
    this.onesecondData = this.localData.firstSection[1].questions;
    this.onethirdData = this.localData.firstSection[2].questions;
    this.oneforthData = this.localData.firstSection[3].questions;
    this.onefifthData = this.localData.firstSection[4].questions;
    this.onequestions.push(this.localData.firstSection[0].qtxt);
    this.onequestions.push(this.localData.firstSection[1].qtxt);
    this.onequestions.push(this.localData.firstSection[2].qtxt);
    this.onequestions.push(this.localData.firstSection[3].qtxt);
    this.onequestions.push(this.localData.firstSection[4].qtxt);

    this.twofirstData = this.localData.secondSection[0].questions;
    this.twosecondData = this.localData.secondSection[1].questions;
    this.twothirdData = this.localData.secondSection[2].questions;
    this.twoforthData = this.localData.secondSection[3].questions;
    this.twofifthData = this.localData.secondSection[4].questions;
    this.twoquestions.push(this.localData.secondSection[0].qtxt);
    this.twoquestions.push(this.localData.secondSection[1].qtxt);
    this.twoquestions.push(this.localData.secondSection[2].qtxt);
    this.twoquestions.push(this.localData.secondSection[3].qtxt);
    this.twoquestions.push(this.localData.secondSection[4].qtxt);

    this.threefirstData = this.localData.thirdSection[0].questions;
    this.threesecondData = this.localData.thirdSection[1].questions;
    this.threethirdData = this.localData.thirdSection[2].questions;
    this.threeforthData = this.localData.thirdSection[3].questions;
    this.threefifthData = this.localData.thirdSection[4].questions;
    this.threequestions.push(this.localData.thirdSection[0].qtxt);
    this.threequestions.push(this.localData.thirdSection[1].qtxt);
    this.threequestions.push(this.localData.thirdSection[2].qtxt);
    this.threequestions.push(this.localData.thirdSection[3].qtxt);
    this.threequestions.push(this.localData.thirdSection[4].qtxt);

    this.forthfirstData = this.localData.forthSection[0].questions;
    this.forthsecondData = this.localData.forthSection[1].questions;
    this.forththirdData = this.localData.forthSection[2].questions;
    this.forthforthData = this.localData.forthSection[3].questions;
    this.forthfifthData = this.localData.forthSection[4].questions;
    this.forthquestions.push(this.localData.forthSection[0].qtxt);
    this.forthquestions.push(this.localData.forthSection[1].qtxt);
    this.forthquestions.push(this.localData.forthSection[2].qtxt);
    this.forthquestions.push(this.localData.forthSection[3].qtxt);
    this.forthquestions.push(this.localData.forthSection[4].qtxt);

    this.fifthfirstData = this.localData.fifthSection[0].questions;
    this.fifthsecondData = this.localData.fifthSection[1].questions;
    this.fifththirdData = this.localData.fifthSection[2].questions;
    this.fifthforthData = this.localData.fifthSection[3].questions;
    this.fifthquestions.push(this.localData.fifthSection[0].qtxt);
    this.fifthquestions.push(this.localData.fifthSection[1].qtxt);
    this.fifthquestions.push(this.localData.fifthSection[2].qtxt);
    this.fifthquestions.push(this.localData.fifthSection[3].qtxt);

    this.sixthfirstData = this.localData.sixthSection[0].questions;
    this.sixthsecondData = this.localData.sixthSection[1].questions;
    this.sixththirdData = this.localData.sixthSection[2].questions;
    this.sixthforthData = this.localData.sixthSection[3].questions;
    this.sixthquestions.push(this.localData.sixthSection[0].qtxt);
    this.sixthquestions.push(this.localData.sixthSection[1].qtxt);
    this.sixthquestions.push(this.localData.sixthSection[2].qtxt);
    this.sixthquestions.push(this.localData.sixthSection[3].qtxt);

    this.submissions = this.fireServ.getSubmissions();

    // console.log(this.twosecondData);
  }
  // / constr

  go(id: string) {
    this.router.navigate(['/assessment/' + id]);
  }

  onDateSelect($event) {
    this.submissions = this.fireServ.setDate($event);
  }

  ngOnInit(): void {}
}
